import { Box, Container } from "@mui/material";
import { PropsWithChildren } from "react";

export interface TopHeaderBannerProps extends PropsWithChildren {
    image: string;
}
const TopHeaderBanner: React.FC<TopHeaderBannerProps> = (props) => {
    const { children, image } = props;


    return <Box
        position="relative"
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="auto"
        minHeight={{ md: "387px" }}
        maxHeight={{
            xs: "400px", md: "624px"
        }}
        sx={{ backgroundColor: "#eee" }}
    >
        <Box
            display="inline-block"
            component="img"
            src={image}
            alt=""
            sx={{
                position: "absolute",
                width: { xs: "auto", md: "100%" },
                height: { xs: "100%", md: "auto" }
            }}
        />

        <Container sx={{ zIndex: 0, py: 6 }}>{children}</Container>
    </Box >;
}

export default TopHeaderBanner;