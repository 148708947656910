import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { globalStyles, joinSx } from "../utils/style";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_resourse, Brand_esteve, Brand_everpharma, Brand_lotus, Brand_pharmathenferrer, ProductImg_logoEcholight, ProductImg_logoImed, ProductImg_logoJacobson, ProductImg_logoLotus, ProductImg_logoSerb,  } from "src/assets";
import Paths from "../utils/Paths";


const brands = [
    // {
    //     title: "Scigen", img: Brand_scigen,
    //     href: "https://scigen.com"
    // },
    {
        title: "Lotus", img: Brand_lotus,
        href: "https://www.lotuspharm.com"
    },
    {
        title: "ESteve", img: Brand_esteve,
        href: "https://www.esteve.com"
    },
    {
        title: "Pharmathen", img: Brand_pharmathenferrer,
        href: "https://www.pharmathen.com"
    },
    {
        title: "Everpharma", img: Brand_everpharma,
        href: "https://www.everpharma.com"
    },
    {
        title: "Serb", img: ProductImg_logoSerb,
        href: Paths.Brand_serb
    },
    {
        title: "Jacobson", img: ProductImg_logoJacobson,
        href: Paths.Brand_jacobson
    },
    {
        title: "i+Med", img: ProductImg_logoImed,
        href: Paths.Brand_iMed
    },
    {
        title: "Echolight", img: ProductImg_logoEcholight,
        href: Paths.Brand_echolight
    }
]
export interface ResourceLinksProps extends PropsWithChildren {

}
const ResourceLinks: React.FC<ResourceLinksProps> = (props) => {
    
    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_resourse}>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Resource</Typography>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Links</Typography>
        </TopHeaderBanner>
        <Container sx={{ py: 4 }}>
            <Grid container pb={5}>
                {
                    brands.map((brand, index) => (
                        <Grid item xs={6} md={3} textAlign="center" pb={12} key={index}>
                            <Box px={2} minHeight="100px"><img width="100%" src={brand.img} alt="" /></Box>
                            {(brand.href !=="" && brand.href !=="#") && <Button variant="contained" onClick={() => {window.open(`${brand.href}`, "_blank")}} color="warning" sx={joinSx(globalStyles.commonButton, { mt: 3 })}>Read More</Button>}
                        </Grid>
                    ))
                }
            </Grid>
        </Container>


        <MyFooter />
    </>;
}

export default ResourceLinks;