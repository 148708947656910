import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { createStyles, globalStyles, joinSx } from "../utils/style";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_productServices, Product_img_symbol_globalDrugSourcing, Product_img_symbol_pharmaBenefitMgmt, Product_img_symbol_salesMarketing, Product_img_symbol_warehouse } from "src/assets";
import Paths from "../utils/Paths";
import { useNavigate } from "react-router-dom";

const styles = createStyles({
    allColumn: {
        backgroundColor: "#FAFAFA",
        borderRadius: "40px"
    }
})

const services = [
    {
        img: Product_img_symbol_globalDrugSourcing,
        title: "Global Drug Sourcing",
        href: Paths.Services_GlobalDrugSourcing
    },
    {
        img: Product_img_symbol_pharmaBenefitMgmt,
        title: "Pharmacy Benefit Management",
        href: Paths.Services_PharmaBenefitMgmt
    },
    {
        img: Product_img_symbol_warehouse,
        title: "Warehouse and Distribution",
        href: Paths.Services_WarehouseDistribution
    },
    {
        img: Product_img_symbol_salesMarketing,
        title: "Sales and Marketing",
        href: Paths.Services_SalesMarketing
    },
]

export interface ProductServicesProps extends PropsWithChildren {

}
const ProductServicesPage: React.FC<ProductServicesProps> = (props) => {

    const navigate = useNavigate();
    
    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_productServices}>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Our </Typography>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Services</Typography>
        </TopHeaderBanner>
        <Container sx={{ pt: 4, pb: 8 }}>
            <Typography variant="h5" pb={2} textAlign="center">Our Products & Services</Typography>
            <Typography variant="body2" pb={2} textAlign="center">Teraju Pharma provides our future clients and partners with different services catered to their needs. Here are just some of the services our company are able to help you with:</Typography>

            <Grid container spacing={2}>
                {
                    services.map((service, index) => (
                        <Grid item xs={6} md={3} key={index}>
                            <Box display="flex" flexWrap="wrap" alignContent="space-between" height="100%" textAlign="center" sx={styles.allColumn} >
                                <Box pt={2} mx="auto">
                                    <Box component="img" src={service.img} alt="" width="100px" height="100px"></Box>
                                    <Typography variant="subtitle2" px={3} py={2}>{service.title}</Typography>
                                </Box>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    sx={joinSx({ my: 3, mx: "auto" }, globalStyles.commonButton)}
                                    onClick={() => {navigate(service.href)}}
                                >
                                    Read More
                                </Button>
                            </Box>
                        </Grid>
                    ))
                }
            </Grid>

        </Container>


        <MyFooter />
    </>;
}

export default ProductServicesPage;