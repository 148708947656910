import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { createStyles } from "../utils/style";
import { Box, Button, Chip, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_productList, ProductImg_Alsuni_12_5mg, ProductImg_besartin_150mg, ProductImg_besartin_300mg, ProductImg_Lenalidomide_25mg, ProductImg_Lenalidomide_5mg, ProductImg_logoLotus, ProductImg_Veiasu_250mg, ProductImg_Vinorelbine_20mg, ProductImg_Vinorelbine_30mg, } from "src/assets";
import { useNavigate } from "react-router-dom";
import Paths from "../utils/Paths";
import { ChevronLeft } from "@mui/icons-material";
import Slider from "react-slick";


const styles = createStyles({
    divider: {
        marginY:3
    },
});
export interface LotusProps extends PropsWithChildren {

}
const LotusPage: React.FC<LotusProps> = (props) => {
    const navigate = useNavigate();

    const alvogenImages = [
        ProductImg_Lenalidomide_5mg,
        ProductImg_Lenalidomide_25mg,
      ];
      const vinobinImages = [
        ProductImg_Vinorelbine_20mg,
        ProductImg_Vinorelbine_30mg,
      ];
      const bersatinImages = [
        ProductImg_besartin_300mg,
        ProductImg_besartin_150mg,
      ];

    return <>
        <TopNavigation />
        <TopHeaderBanner image={Banner_productList} />

        <Container sx={{ py: 4 }}>
            <Button sx={{color:"GrayText", mb:3}} size="small" onClick={() => {navigate(Paths.Products)}} startIcon={<ChevronLeft />}>Back</Button>
            <Typography variant="h5">Our Product</Typography>
            <Box width="300px" component="img" src={ProductImg_logoLotus} pt={3} alt=""></Box>
            <Box pt={3}>
                <Box pb={2}>
                    <Grid container>
                        <Grid item xs={6} md={2}><img width="100%" src={ProductImg_Alsuni_12_5mg} alt="" /></Grid>
                        <Grid item xs={6} md={10} pl={3}>
                            <Typography variant="body2">
                                <p>
                                    <Typography variant='h5' sx={{fontWeight:"bolder"}}>Alsuni</Typography>
                                    <Stack direction="row" spacing={1} alignItems='center'>
                                        <Typography variant='h6' sx={{fontWeight:"bold"}} color='primary'>Sunitinib Malate | Oral | Capsules</Typography>
                                        <Chip label="12.5mg" color="primary" />
                                    </Stack>
                                </p>
                                <p>
                                    Alsuni is a tyrosine kinase inhibitor indicated for: 
                                    <ul>
                                        <li>Advanced renal cell carcinoma.</li>
                                        <li>Gastrointestinal stromal tumour (GIST) after disease progression on or intolerance to imatinib mesylate.</li>
                                        <li>Unresectable or metastatic, well-differentiated pancreatic neuroendocrine tumours (pNET) with diseasae progression in adults.</li>
                                        
                                    </ul>
                                </p>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={styles.divider} />
                    <Grid container>
                        <Grid item xs={6} md={2}><img width="100%" src={ProductImg_Veiasu_250mg} alt="" /></Grid>
                        <Grid item xs={6} md={10} pl={3}>
                            <Typography variant="body2">
                                <p>
                                    <Typography variant='h5' sx={{fontWeight:"bolder"}}>Veiasu</Typography>
                                    <Stack direction="row" spacing={1} alignItems='center'>
                                        <Typography variant='h6' sx={{fontWeight:"bold"}} color='primary'>Gefitinib | Oral | Film-Coated Tablets</Typography>
                                        <Chip label="250MG" color="primary" />
                                    </Stack>
                                </p>
                                <p>
                                    Veiasu is a tyrosine kinase inhibitor indicated for:
                                    <ul>
                                        <li>Locally advanced or metastatic non-small cell lung cancer (NSCLC) with activating mutations of EGFR-TK.</li>
                                        
                                    </ul>
                                </p>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={styles.divider} />
                    <Grid container>
                        <Grid item xs={6} md={2}>
                            <Slider slidesToShow={1} slidesToScroll={1} dots={true} infinite={true}>
                                {
                                    alvogenImages.map((URL, index) => (
                                        <img alt="Alvogen Lenalidomide" src={URL} key={index} />
                                    ))
                                }
                            </Slider>
                        </Grid>
                        <Grid item xs={6} md={10} pl={3}>
                            <Typography variant="body2">
                                <p>
                                    <Typography variant='h5' sx={{fontWeight:"bolder"}}>Alvogen Lenalidomide</Typography>
                                    <Stack direction="row" spacing={1} alignItems='center'>
                                        <Typography variant='h6' sx={{fontWeight:"bold"}} color='primary'>Lenalidomide | Oral | Capsules</Typography>
                                        <Chip label="5MG" color="primary" /><Chip label="25MG" color="primary" />
                                    </Stack>
                                </p>
                                <p>
                                    Alvogen Lenalidomide is an immunomodulatory agent indicated for:
                                    <ul>
                                        <li>Newly diagnosed multiple myeloma (MM) who have undergo autologous stem cell transplatation in adults. Used as monotherapy for maintenance treatment.</li>
                                        <li>Previously untreated multiple myeloma (MM) who are ineligible for transplant. Used in combination with dexamethasone. </li>
                                        <li>Multiple myeloma (MM) who have received at least one prior therapy. Used in combination with dexamethasone.</li>
                                    </ul>
                                </p>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={styles.divider} />
                    <Grid container>
                        <Grid item xs={6} md={2}>
                            <Slider slidesToShow={1} slidesToScroll={1} dots={true} infinite={true}>
                                {
                                    vinobinImages.map((URL, index) => (
                                        <img alt="Vinobin" src={URL} key={index} />
                                    ))
                                }
                            </Slider>
                        </Grid>
                        <Grid item xs={6} md={10} pl={3}>
                            <Typography variant="body2">
                                <p>
                                    <Typography variant='h5' sx={{fontWeight:"bolder"}}>Vinobin</Typography>
                                    <Stack direction="row" spacing={1} alignItems='center'>
                                        <Typography variant='h6' sx={{fontWeight:"bold"}} color='primary'>Vinorelbine | Oral | Soft Capsules</Typography>
                                        <Chip label="20MG" color="primary" /><Chip label="30MG" color="primary" />
                                    </Stack>
                                </p>
                                <p>
                                    Vinobin is a vinca alkaloid indicated for:
                                    <ul>
                                        <li>Advanced Breast Cancer.</li>
                                        <li>Non-small cell lung cancer (NSCLC).</li>
                                    </ul>
                                </p>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={styles.divider} />
                    <Grid container>
                        <Grid item xs={6} md={2}>
                            
                        </Grid>
                        <Grid item xs={6} md={10} pl={3}>
                            <Typography variant="body2">
                                <p>
                                    <Typography variant='h5' sx={{fontWeight:"bolder"}}>RENOPLAST</Typography>
                                    <Stack direction="row" spacing={1} alignItems='center'>
                                        <Typography variant='h6' sx={{fontWeight:"bold"}} color='primary'></Typography>
                                        {/* <Chip label="20MG" color="primary" /><Chip label="30MG" color="primary" /> */}
                                    </Stack>
                                </p>
                                <p>
                                    RENOPLAST is a multi-kinase inhibitor indicated for:
                                    <ul>
                                        <li>The treatment of patients with hepatocellular carcinoma.</li>
                                        <li>The treatment of patients with advanced renal cell carcinoma (RCC) who have failed prior interferon-alpha or interleukin-2 based therapy or are considered unsuitable for such therapy. </li>
                                        <li>The treatment of patients with locally advanced or metastatic, progressive, differentiated thyroid carcinoma refractory to radioactive iodine.</li>
                                    </ul>
                                </p>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={styles.divider} />
                    <Grid container>
                        <Grid item xs={6} md={2}>
                            <Slider slidesToShow={1} slidesToScroll={1} dots={true} infinite={true}>
                                {
                                    bersatinImages.map((URL, index) => (
                                        <img alt="BESARTIN" src={URL} key={index} />
                                    ))
                                }
                            </Slider>
                        </Grid>
                        <Grid item xs={6} md={10} pl={3}>
                            <Typography variant="body2">
                                <p>
                                    <Typography variant='h5' sx={{fontWeight:"bolder"}}>BESARTIN</Typography>
                                    <Stack direction="row" spacing={1} alignItems='center'>
                                        <Typography variant='h6' sx={{fontWeight:"bold"}} color='primary'>Film coated tablets</Typography>
                                        <Chip label="300MG" color="primary" /><Chip label="150MG" color="primary" />
                                    </Stack>
                                </p>
                                <p>
                                    BESARTIN is an angiotensin-II receptor blocker (ARB) indicated for:
                                    <ul>
                                        <li>The treatment of essential hypertension.</li>
                                        <li>The treatment of diabetic nephropathy with an elevated serum creatinine and proteinuria ({">"}300 mg/day) in patients with type 2 diabetes and hypertension.</li>
                                    </ul>
                                </p>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={styles.divider} />
                    <Grid container>
                        <Grid item xs={6} md={2}>
                            {/* <Slider slidesToShow={1} slidesToScroll={1} dots={true} infinite={true}>
                                {
                                    bersatinImages.map((URL, index) => (
                                        <img alt="BESARTIN" src={URL} key={index} />
                                    ))
                                }
                            </Slider> */}
                        </Grid>
                        <Grid item xs={6} md={10} pl={3}>
                            <Typography variant="body2">
                                <p>
                                    <Typography variant='h5' sx={{fontWeight:"bolder"}}>PROSDROP</Typography>
                                    <Stack direction="row" spacing={1} alignItems='center'>
                                        <Typography variant='h6' sx={{fontWeight:"bold"}} color='primary'></Typography>
                                        {/* <Chip label="300MG" color="primary" /><Chip label="150MG" color="primary" /> */}
                                    </Stack>
                                </p>
                                <p>
                                    PROSDROP eye drops is indicated for:
                                    <ul>
                                        <li>Reduction of elevated intraocular pressure (IOP) in patients with:
                                            <ul>
                                                <li>Open-angle glaucoma</li>
                                                <li>Chronic angle closure glaucoma</li>
                                                <li>Ocular hypertension</li>
                                            </ul>
                                        </li>
                                        <li>Reduction of elevated intraocular pressure in paediatric patients with elevated intraocular pressure and paediatric glaucoma.</li>
                                    </ul>
                                </p>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                
            </Box>
        </Container>


        <MyFooter />
    </>;
}

export default LotusPage;