import { Box, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import React, { PropsWithChildren, useState } from "react";
import { SimpleMap } from "../utils/type";
import { useNavigate } from "react-router-dom";
import { CloseSharp, ExpandLess, ExpandMore } from "@mui/icons-material";

export interface DrawerItemProps extends PropsWithChildren {
    // label: string;
    // href: string;
    // sub: SimpleMap<any>[];
    item: SimpleMap;
    sub: SimpleMap<any>[];
}
const DrawerItem: React.FC<DrawerItemProps> = (props) => {
    const { item, sub } = props;
    const [subOpen, setSubOpen] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        setSubOpen(!subOpen);
    };
    
    return <>
        <ListItem disablePadding>
            <ListItemButton  >
                <ListItemText primary={item.label} onClick={() => navigate(item.href)} />
                {!!(item.sub.length > 0) && (subOpen ? <ExpandLess onClick={handleClick} /> : <ExpandMore onClick={handleClick} />)}
            </ListItemButton>
        </ListItem>
        {!!(item.sub.length > 0) && (
            <Collapse in={subOpen} unmountOnExit>
                <Divider />
                <List component="div" disablePadding>
                    {
                        sub.map((subItem: SimpleMap, index: number) => (
                            <ListItemButton key={index} sx={{ pl: 4 }} onClick={() => {navigate(subItem.href);}}>
                                <Typography variant="body2" >{subItem.label}</Typography>
                            </ListItemButton>
                        ))
                    }
                </List>
                <Divider />
            </Collapse>
        )}
    </>
}
export interface MyDrawerProps extends PropsWithChildren {
    navItems: SimpleMap<any>[];
    drawerOpenFlag: boolean;
    drawerToggle: () => void;
}
const MyDrawer: React.FC<MyDrawerProps> = (props) => {
    const { navItems, drawerOpenFlag = false, drawerToggle } = props;

    return (
        <Box component="nav">
            <Drawer
                variant="temporary"
                open={drawerOpenFlag}
                onClose={drawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                }}
            >
                <Box>
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                        <IconButton onClick={drawerToggle}>
                            <CloseSharp />
                        </IconButton>
                    </Box>
                    <Divider />
                    <List component="nav">
                        {navItems.map((item, index) => (
                            <DrawerItem key={index} item={item} sub={item.sub} />
                            
                        ))}
                    </List>
                </Box>
            </Drawer>
        </Box>
    );
}


export default MyDrawer;