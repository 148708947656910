import React, { PropsWithChildren, useRef } from "react";
import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import { createStyles } from "../utils/style";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const styles = createStyles({
    menuButton: {
        marginLeft: "15px",
        color: "#555",
        "&.MuiButtonBase-root ":{
            
        }
    },
    muiPaperRoot: {
        ".MuiPaper-root": {
            minWidth: "150px"
        }
    }
});
export interface MyMenuProps extends PropsWithChildren {
    menuItems: any[];
    label: string;
    href: string;
}
const MyMenu: React.FC<MyMenuProps> = (props) => {
    const { menuItems, label, href } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const buttonRef = useRef(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // setAnchorEl(event.currentTarget);
        setAnchorEl(buttonRef.current);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <Box >
        <Button
            {...{
                key: label,
                to: href,
                component: RouterLink,
                sx: styles.menuButton
            }}
            ref={buttonRef}
            aria-controls={menuItems.length > 0 ? `menu-${label}` : undefined}
            aria-haspopup={menuItems.length > 0 ? "true" : undefined}
            aria-expanded={menuItems.length > 0 ? 'true' : undefined}
        >
            {label}
        </Button>
        {!!(menuItems.length > 0) && <IconButton sx={{ p: 0 }} onClick={menuItems.length > 0 ? handleClick : () => { }}><KeyboardArrowDownIcon /></IconButton>}
        {!!(menuItems.length > 0) && <Menu
            sx={styles.muiPaperRoot}
            id={`menu-${label}`}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}>
            {menuItems.map(({ label, href }, index) => <MenuItem {...{ to: href, component: RouterLink }} key={index} ><Typography>{label}</Typography></MenuItem>)}
        </Menu>}
    </Box>;
}

export default MyMenu;