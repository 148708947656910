import React, { PropsWithChildren, useEffect, useState } from "react"
import TopNavigation from "../components/topNav";
import { Box, Button, Container, Divider, Grid, Typography, useTheme } from "@mui/material";
import { createStyles, globalStyles, joinSx } from "../utils/style";
import MyFooter from "../components/footer";
import TopHeaderBanner from "../components/topHeaderBanner";
import { useNavigate } from "react-router-dom";
import {
    Banner_home,
    // Client1, Client2, Client3, Client4, Client6, Client7, Client8, Client9, Client10, Client11,
    OurServices_img, WWD_innovation, WWD_partnership, WWD_quality
} from "src/assets";
import Paths from "../utils/Paths";
// import Slider from "react-slick"; //https://github.com/akiran/react-slick
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ContactForm from "../components/contactForm";
import { getNewsAPI } from "../services/api";
import { NewsAPIResponse } from "./NewsListing";

// const clients = [
//     Client1, Client2, Client3, Client4, Client6, Client7, Client8, Client9, Client10, Client11
// ]
interface NewsCompProps extends PropsWithChildren {
    news: NewsAPIResponse;
}
const NewsComp: React.FC<NewsCompProps> = (props) => {
    const { news } = props;
    const theme = useTheme();
    const navigate = useNavigate();

    const handleParagraph = (content: string) => {
        const newContent = content.substring(0, 200).split("\n\n");

        return (<>
            {
                newContent.map((item, index) => (
                    <Typography key={index} >{item} {index === newContent.length - 1 && "..."}</Typography>
                ))
            }
        </>)
    }

    return (
        <Grid item xs={12} md={4} >
            <Box sx={styles.newsGridItem} onClick={() => { navigate(`${Paths.NewsListing}#news${news.ID}`) }}>
                <Container sx={{ py: 5 }}>
                    <Typography fontWeight={700}>
                        {news.date}
                    </Typography>
                    <Divider sx={{ borderColor: theme.palette.warning.main, borderWidth: 3, my: 3, mx: "auto", width: "50px" }} />
                    <Typography>
                        {news.title}
                    </Typography>
                    <Typography>
                        {handleParagraph(news.summary)}
                    </Typography>
                </Container>
            </Box>
        </Grid>
    );
}
interface HomePageProps extends PropsWithChildren {

}
const HomePage: React.FC<HomePageProps> = (props) => {

    const theme = useTheme();
    const navigate = useNavigate();
    const [newNewsListing, setNewNewsListing] = useState<NewsAPIResponse[]>();

    const fetchNews = async () => {
        try {
            const res = await getNewsAPI();
            setNewNewsListing(res);
        }
        catch (error) {
            console.log("error")
        }
    }

    useEffect(() => {
        fetchNews();
    }, [])

    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_home}>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Leading</Typography>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Accessible</Typography>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Innovation</Typography>
            <Button
                onClick={() => { navigate(Paths.AboutUs) }}
                variant="contained"
                color="warning"
                sx={joinSx({ mt: 4 }, globalStyles.buttonShadow, globalStyles.commonButton)}>
                Learn More
            </Button>
        </TopHeaderBanner>

        <Box sx={{ backgroundColor: theme.palette.primary.main }}>
            <Container sx={{ py: 7 }}>
                <Grid container>
                    <Grid item xs={12} md={5} pb={4}>
                        <Typography variant="h5" color="white" pb={4}>What We Do</Typography>
                        <Typography variant="h2" color="white">
                            Leading Accessible Innovation
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Divider />
                        <Typography color="white" py={5} textAlign="justify">
                            At Teraju Pharma, we aim to be the leaders of accessible innovation. Providing equal access to innovative solutions for people around the world. We establish great relationships with our partners and customers by providing services of superior quality. Doing this in an ethical and sustainable way.
                        </Typography>
                        <Box display={{ md: "flex" }} alignItems="center" pb={5}>
                            <Box display="flex" alignItems="center" pb={2} pr={3}>
                                <Box component="img" src={WWD_innovation} alt="" height="40px" pr={1} />
                                <Typography variant="subtitle1" color="white">INNOVATION</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" pb={2} pr={3}>
                                <Box component="img" src={WWD_quality} alt="" height="40px" pr={1} />
                                <Typography variant="subtitle1" color="white">QUALITY</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" pb={2} >
                                <Box component="img" src={WWD_partnership} alt="" height="40px" pr={1} />
                                <Typography variant="subtitle1" color="white">PARTNERSHIP</Typography>
                            </Box>
                        </Box>
                        <Button onClick={() => { navigate(Paths.AboutUs) }} variant="contained" color="warning" sx={globalStyles.commonButton}>Learn More</Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        <Box sx={{ backgroundColor: "#eee" }}>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box overflow="hidden" display="flex" alignItems="center">
                        <Box component="img" width={{ xs: "100%", md: "unset" }} height={{ xs: "unset", md: "100%" }} src={OurServices_img} alt=""></Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box px={{ md: 4 }} display="flex" alignItems="center" justifyContent="center" height="100%">
                        <Container sx={{ py: 3, pr: { xl: 20 } }}>
                            <Typography variant="h5" pb={4}>Our Service</Typography>

                            <Typography pb={3} textAlign="justify">
                                Leading Asia’s pharmaceutical industries, we take pride in being the innovators of healthcare.

                                Our medicines are sourced globally, while providing focus on specialty medicines and nutrition. We are also a vital player in orphan drugs medication.

                                Finally, being customer-oriented, Teraju Pharma has always listened to the needs of neglected patients and ambitious partners.
                            </Typography>
                            <Button onClick={() => { navigate(Paths.ProductServices) }} variant="contained" color="warning" sx={globalStyles.commonButton}>Learn More</Button>
                        </Container>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        {/* <Container sx={{ p:6}}>
            <style>
                {`
                    .slick-dots li.slick-active button:before {color: ${theme.palette.warning.main};}
                    .slick-dots li button:before {font-size:12px; }
                    .slick-prev, .slick-next {color: ${theme.palette.grey[500]}}
                    .slick-prev:hover, .slick-next:hover {color:${theme.palette.grey[700]}}
                `}
            </style>
            <Slider {...{
                dots: true,
                infinite: true,
                arrows: true,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 3,
                prevArrow: <ArrowBackIosIcon />,
                nextArrow: <ArrowForwardIosIcon />,
                responsive: [
                    {
                        breakpoint: 450,
                        settings: { slidesToShow: 1, slidesToScroll: 1, },
                    },
                ],
            }}>
                {clients.map((client, index) => (
                    <Box p={0} key={index} ><Box component="img" src={client} alt="" mx="auto" /></Box>
                ))}

            </Slider>
        </Container> */}
        <Box py={6}>
            <Typography variant="h5" textAlign="center" pb={3}>
                News & Updates
            </Typography>
            <Grid container spacing={1}>
                {newNewsListing && newNewsListing.slice(0, 3).map((item, index) => (
                    <NewsComp news={item} key={index} />
                ))}
            </Grid>
            <Box display="flex" justifyContent="center" pt={2}>
                <Button onClick={() => { navigate(Paths.NewsListing) }} variant="contained" color="warning" sx={{ px: 4 }}>See All</Button>
            </Box>
        </Box>
        <Box py={3}>
            <Container>
                <Typography variant="h5" pb={4}>Drop Us A Message</Typography>
                <ContactForm />
            </Container>
        </Box>
        <MyFooter />
    </>;
}
const styles = createStyles({
    newsGridItem: {
        backgroundColor: "#eee",
        height: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
        ":hover": { backgroundColor: "secondary.main", color: "white" },
        cursor: "pointer"
    },
    bannerH1Text: {
        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.7)"
    }
});
export default HomePage;