import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { createStyles, globalStyles, joinSx } from "../utils/style";
import MyFooter from "../components/footer";
import { Banner_contactUs, EmailIcon, PhoneIcon, WarehouseIcon } from "src/assets";
import ContactForm from "../components/contactForm";

const styles = createStyles({
    allIcons: { pr: 2 },
})
export interface ContactPageProps extends PropsWithChildren {

}
const ContactPage: React.FC<ContactPageProps> = (props) => {

    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_contactUs}>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Contact Us</Typography>
        </TopHeaderBanner>

        <Container sx={{ py: 4 }}>
            <Typography variant="h5" pb={4}>Drop Us A Message</Typography>

            <ContactForm />
        </Container>
        <Grid container>
            <Grid item xs={12} md={6} minHeight="400px">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.188872871901!2d101.62018851400039!3d3.044002697783244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4b50a70427dd%3A0x547d3288713634bd!2sTERAJU+PHARMA+SDN+BHD!5e0!3m2!1sen!2smy!4v1564648300694!5m2!1sen!2smy"
                    title="Teraju Pharma"
                    width="100%" height="100%"
                    loading="lazy"
                    sandbox="allow-scripts"
                    style={{ border: "0" }}
                    allowFullScreen={true}>
                </iframe>
            </Grid>
            <Grid item xs={12} md={6} sx={{ backgroundColor: "primary.main" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                    <Container sx={{ py: 4, pr: { xl: 20 } }}>
                        <Typography variant="h5" color="white" pb={1}>Connect with us</Typography>
                        <Divider sx={joinSx(globalStyles.contentDivider, { width: "20%", my: 4 })} />
                        <Box color="white" >
                            <Box display="flex" alignItems="center" pb={3}>
                                <Box component="img" maxWidth="30px" src={EmailIcon} sx={styles.allIcons} />
                                <Typography>contact@terajupharma.com</Typography>
                            </Box>
                            
                            <Box display="flex" alignItems="start" pb={3} >
                                <Box component="img" maxWidth="30px" src={WarehouseIcon} sx={styles.allIcons} />
                                <Typography>
                                    <Typography color="white" pb={1} fontWeight='900'>
                                        Teraju Pharma Sdn Bhd (Warehouse)
                                    </Typography>
                                    28, Jalan Astana 1D/KU2, Bandar Bukit Raja, 41050 Klang, Selangor, Malaysia
                                </Typography>
                           </Box>
                           <Box display="flex" alignItems="start" pb={3}>
                                <Box component="img" maxWidth="30px" src={WarehouseIcon} sx={styles.allIcons} />
                                <Typography>
                                    <Typography color="white" pb={1} fontWeight='900'>
                                        Teraju Pharma Sdn Bhd (Sales & Marketing)
                                    </Typography>
                                    65, Level 1, Jln Setia Gemilang BG U13/BG, Setia Alam, 40170 Shah Alam, Selangor
                                </Typography>
                           </Box>
                            <Box display="flex" alignItems="center" pb={3}>
                                <Box component="img" maxWidth="30px" src={PhoneIcon} sx={styles.allIcons} />
                                <Typography>+603 3345 9898</Typography>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </Grid>
        </Grid>
        <MyFooter />
    </>;
}

export default ContactPage;