import { SxProps, Theme } from "@mui/material";

export const joinSx = (...sxArgs: (SxProps<Theme> | undefined)[]) => {
  const result = {};
  for (const sx of sxArgs)
    if (sx)
      Object.assign(result, sx);
  return result;
}

export const createStyles = <T extends { [index: string]: SxProps }>(args: T): { [key in keyof T]: SxProps } => {
  return args;
}

export const globalStyles = createStyles({
  bannerHeaderText: {
    fontFamily: "Akrobat",
    color: "white",
    textShadow: "5px 5px 10px rgba(0, 0, 0, 0.7)"
  },
  commonButton: { px: 4 },
  buttonShadow: { boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.7)", px: 4 },
  contentDivider: { borderWidth: "3px", borderColor: "warning.main" },
  inputField: {
    "& .MuiFilledInput-root": {
      borderRadius: "0px",
      ":before": { border: 0 }
    },
    "& .MuiFilledInput-input": {
      borderRadius: "0px"
    }
  }
});
