import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { globalStyles } from "../utils/style";
import { Container, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_aboutUs } from "src/assets";

export interface PrivacyPageProps extends PropsWithChildren {

}
const PrivacyPage: React.FC<PrivacyPageProps> = (props) => {

    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_aboutUs}>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Privacy Policy</Typography>
        </TopHeaderBanner>
        <Container sx={{ py: 4 }}>
            <List>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">1</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Our Commitment to Your Privacy </Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">1.1</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">This website is owned and operated by Teraju Pharma Sdn Bhd (hereinafter referred as “we”, “us” or “our”).  We are committed in protecting the privacy and security of your personal data (as defined in the Personal Data Protection Act 2010 of Malaysia (“PDPA”). </Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">1.2</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">This privacy policy (“Policy”) describes how we collect, maintain, use, disclose, process and safeguard your personal data and the personal data of any third-party individual, including any sensitive personal data (as defined in the PDPA), as may be voluntarily or required to be provided by you in the course of you utilising our website. This Policy also tells you about your rights and how the applicable laws protect you.</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">1.3</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">This Policy supplements the terms of use on the website and is not intended to override them. </Typography>} />
                </ListItem>

                <ListItem disablePadding alignItems="flex-start" sx={{ pt: 4 }}>
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">2</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Your Consent Is Important</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">2.1</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">We value your privacy and will protect your personal data as provided by you in the course of utilising the website in compliance with the laws of Malaysia, in particular the PDPA.</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">2.2</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">Please read this Policy in its entirety, as it serves to help you make informed decisions about how we collect, use and process your personal data. </Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">2.3</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">We will only collect and use your personal data in accordance with such applicable laws (including the PDPA). </Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">2.4</Typography>} />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body2">This Policy explains: </Typography>}
                        secondary={
                            <List>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">2.4.1</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">what kind of personal data we collect about you and how we collect it from you;</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">2.4.2</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">how we use your personal data;</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">2.4.3</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">the parties that we disclose your personal data to; </Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">2.4.4</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">your rights in respect of your personal data; and</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">2.4.5</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">the choices we offer, including how to access or update your personal data.</Typography>} />
                                </ListItem>
                            </List>
                        }
                    />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">2.5</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">By providing us your personal data either at our request or voluntarily, using our website and communicating with us on the website, you hereby consent to our collection, use, processing and disclosure of your personal data in accordance with this Policy. </Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">2.6</Typography>} />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body2">If you provide us information on behalf of someone else, you represent and warrant to us that the other person has appointed you to act on his/her behalf and has fully agreed that you have the authority to: </Typography>}
                        secondary={
                            <List>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">2.6.1</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">give consent on his/her behalf to the processing of his/her personal data;</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">2.6.2</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">receive on his/her behalf any privacy notice; and</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">2.6.3</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">give consent to the transfer of his/her personal data abroad.</Typography>} />
                                </ListItem>
                            </List>
                        }
                    />
                </ListItem>

                <ListItem disablePadding alignItems="flex-start" sx={{ pt: 4 }}>
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">3</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Information Collection and Use</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">3.1</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">in order to enable us to deal with your enquiries, if any, we may need to and/or may be required to collect, record, hold, use, disclose and store (“process”) personal data about you, to establish your personal identification and background.</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">3.2</Typography>} />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body2">We may, from time to time, collect the following personal data and information from you:</Typography>}
                        secondary={
                            <List>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">3.2.1</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">Contact Information, such as: your name, alias, email address, mailing address and phone number, delivery address, and residential address; </Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">3.2.2</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">Personal Identification Information, such as: your age, gender, nationality, date of birth, professional or job title (including your identity card number or passport number); </Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">3.2.3</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">Marketing and Communications Information, such as: your preferences in receiving marketing material and communications, if any, from us and any third party,</Typography>} />
                                </ListItem>
                            </List>
                        }
                    />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">3.3</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">The types of personal data set out under Clause 3.2 is not a definitive or exhaustive list of all the personal data that we will collect from you. Nonetheless, we will only collect your personally identifiable information where it is necessary to provide our services to you and to accurately establish or verify the identities of individuals to a high degree of fidelity.</Typography>} />
                </ListItem>

                <ListItem disablePadding alignItems="flex-start" sx={{ pt: 4 }}>
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">4</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Purposes of Processing Your Personal Data</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">4.1</Typography>} />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body2">General:</Typography>}
                        secondary={
                            <List>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">4.1.1</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">to respond to your enquiries, feedback and complaints;</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">4.1.2</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">to improve the quality, efficiency and utilisation of our website;</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">4.1.3</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">to maintain, update, consolidate and improve the accuracy of our internal database records;</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">4.1.4</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">to produce data, reports and statistics which have been anonymised or aggregated in a manner that does not identify you as an individual;</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">4.1.5</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">to conduct research for data analytical purposes;</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">4.1.6</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">to maintain the safety and security of our website; </Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">4.1.7</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">for crime detection, prevention and prosecution; </Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">4.1.8</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">to detect, prevent and address any technical issues arise from our website; and/or</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">4.1.9</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">for any other purpose that is required or permitted by any law, regulations and/or guidelines.</Typography>} />
                                </ListItem>
                            </List>
                        }
                    />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">4.2</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">We will seek your separate consent for any other purposes which do not fall within the purposes stated in this Clause 4.</Typography>} />
                </ListItem>

                <ListItem disablePadding alignItems="flex-start" sx={{ pt: 4 }}>
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">5</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Disclosure of Your Personal data</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">5.1</Typography>} />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body2">In order for us to fulfil the purposes listed above, we may disclose your personal data to the following parties, including, but not limited to:</Typography>}
                        secondary={
                            <List>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">5.1.1</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">companies and/or organisations that act as our strategic partners and/or licensors;</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">5.1.2</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">companies and/or organisations that assist us in providing value added services to you (where necessary);</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">5.1.3</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">any government departments/agencies and statutory authorities in connection with any investigations or enquiries;</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">5.1.4</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">our IT service providers who provide technical infrastructure services, software and development work, analyse how our website is used, or provide customer service to you; and/or</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">5.1.5</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">other parties in respect of whom you have given your express or implied consent.</Typography>} />
                                </ListItem>
                            </List>
                        }
                    />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">5.2</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">With your authorisation, we may disclose your personal data to a third-party. If you authorise this disclosure, the use and disclosure restrictions contained in this Policy will not apply to such third party, as we do not control the privacy practices of any third parties. </Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">5.3</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">The above Clauses Error! Reference source not found. and 5.2 shall be subject, at all times, to any laws (including regulations, standards, guidelines and/or obligations) applicable to us (whether in or outside Malaysia).</Typography>} />
                </ListItem>

                <ListItem disablePadding alignItems="flex-start" sx={{ pt: 4 }}>
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">6</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Disclosure for Marketing and Promotional Purposes</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">6.1</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">If you have given us your permission, we may contact you by mail, telephone, SMS, text/picture/video online instant messaging, email or other correspondence methods about our, or our strategic partners’, sponsors’ or advertisers’ products, services, promotions, special offers, events or activities that may be of interest to you. </Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">6.2</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">We do not and will not disclose, rent, sell or otherwise make available your personal data to third parties for direct marketing purposes.</Typography>} />
                </ListItem>

                <ListItem disablePadding alignItems="flex-start" sx={{ pt: 4 }}>
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">7</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Situations Under Which We Disclose Your Personal Data</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">5.1</Typography>} />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body2">Save as set out above, we will otherwise treat your personal data as private and confidential and will not disclose your information to anyone except in any of the following situations:</Typography>}
                        secondary={
                            <List>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">7.1.1</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">where you have given permission for us to disclose your personal data in accordance with this Policy;</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">7.1.2</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">where we are required or permitted to do so by law;</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">7.1.3</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">where required or authorised by any order of court, tribunal or authority, whether governmental or quasi-governmental with jurisdiction over us;</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">7.1.4</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">where we may transfer rights and obligations under this Policy; and/or</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">7.1.5</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">where we are required to meet our obligations to any relevant regulatory authority.</Typography>} />
                                </ListItem>
                            </List>
                        }
                    />
                </ListItem>

                <ListItem disablePadding alignItems="flex-start" sx={{ pt: 4 }}>
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">8</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Data Security </Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">8.1</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">As we are committed to protecting your personal data, we will take reasonable security arrangements to safeguard your personal data in our possession or control from (a) unauthorised access, collection, use, disclosure, copying, modification or similar risks; and (b) the loss of any storage medium or device on which your personal data is stored. </Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">8.2</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">Any of your personal data provided to us are stored on secure servers located in Malaysia. The nature of the Internet is such that we cannot guarantee or warrant the security of any information you transmit to us via the Internet. No data transmission over the Internet can be guaranteed to be 100% secure. However, we will also apply and maintain an appropriate security procedure to manage and protect the use and storage of records containing your personal data, and this includes regularly reviewing and updating the technical and organisational security measures governing the processing of personal data to be carried out.</Typography>} />
                </ListItem>

                <ListItem disablePadding alignItems="flex-start" sx={{ pt: 4 }}>
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">9</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Your Obligation to Provide Complete Personal Data </Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">9.1</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">Where indicated, we require you to provide your personal data to us to enable us to process your information to enable us to provide our services. Should you decline to provide such obligatory personal data, we may not be able to provide you with our services.</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">9.2</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">You confirm that all your personal data is true, accurate and complete and that none of the personal data provided is misleading or outdated. In the event of any change to your personal data, you will promptly update us on any such change.</Typography>} />
                </ListItem>

                <ListItem disablePadding alignItems="flex-start" sx={{ pt: 4 }}>
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">10</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Your Rights to Access and Correct Your Personal data</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">10.1</Typography>} />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body2">SaveWe can assist you to access and correct your personal data provided to us, and the following will apply: </Typography>}
                        secondary={
                            <List>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">10.1.1</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">You may have access to your personal data collected and stored by us by making a request by writing to us, subject to your payment of the relevant processing fee (if applicable). If we are unable to provide you with access to any of your personal data or to make a correction requested by you, we will provide you with the reasons why we are unable to do so (or if we are not required to do so under the PDPA).</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">10.1.2</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">Please note that we may have to withhold access to your personal data in certain situations, for example when we are unable to confirm your identity or where information requested for is of a confidential nature or in the event where we receive repeated requests for the same information. Nevertheless, we will notify you of the reasons for not being able to accede to your request.</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">10.1.3</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">Where your request for data access is successful and you are of the opinion that such personal data held by us is inaccurate, incomplete, misleading or where relevant, not up-to-date, you may make a request to correct such personal data.</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">10.1.4</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">You can also assist us to keep your personal data (such as your current mailing address) up-to-date, as it will enable us to serve you better.</Typography>} />
                                </ListItem>
                                <ListItem disablePadding alignItems="flex-start">
                                    <ListItemIcon sx={{ mt: 0 }}>
                                        <ListItemText primary={<Typography variant="body2">10.1.5</Typography>} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">Please note that we may use our discretion in allowing the correction or update requested or may require further documentary evidence of the new information to avoid fraud and inaccuracy.</Typography>} />
                                </ListItem>
                            </List>
                        }
                    />
                </ListItem>

                <ListItem disablePadding alignItems="flex-start" sx={{ pt: 4 }}>
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">11</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Transfer of Personal Data</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">11.1</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">We currently do not transfer your personal data outside of Malaysia. In the event that we do, your personal data may be transferred to, stored, used and processed in a jurisdiction other than Malaysia where our servers and/or partners may be located. We will also take all necessary steps to ensure that your personal data continues to receive a standard of protection that is at least comparable to that provided under the PDPA and in accordance with this Policy.</Typography>} />
                </ListItem>

                <ListItem disablePadding alignItems="flex-start" sx={{ pt: 4 }}>
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">12</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Cookies and Tracking</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">12.1</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">We, our Affiliates (if applicable), licensors, and third parties with whom we partner, may use cookies, web beacons, tags, scripts, local shared objects, advertising identifiers and similar technology (“Cookies”) in connection with your use of our website to track the activity on our website and we hold certain Cookies information to track, analyse and improve our services. Cookies may have unique identifiers, and reside, among other places, on your computer or mobile device, in emails we send to you, and on our web pages. Cookies may transmit information about you and your use of our website, such as your browser type, search preferences, IP address, data relating to advertisements that have been displayed to you or that you have clicked on, and the date and time of your use.</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">12.2</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">We may allow third parties to use Cookies on the website to collect the same type of information for the same purposes. Third parties may be able to associate the information they collect with other information they have about you from other sources. We do not necessarily have any responsibility, access to, or control over the Cookies they use.</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">12.3</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">If you do not wish for your personal data to be collected via Cookies on the website, you may deactivate Cookies by adjusting your internet browser settings to disable, block or deactivate cookies, by deleting your browsing history and clearing the cache from your internet browser.</Typography>} />
                </ListItem>

                <ListItem disablePadding alignItems="flex-start" sx={{ pt: 4 }}>
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">13</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Links to Third-Party Websites</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">13.1</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">Our website may contain links to third parties’ websites. If you click on a third-party link, you will be directed to that third party’s site. Please note that we are not responsible for the collection, use, maintenance, sharing, or disclosure of data and information by such third parties. If you provide information directly to such sites, the privacy policy and terms of service on those sites are applicable and we shall not be responsible for the information processing practices or privacy policies of such sites. We strongly advise you to review the privacy policy of every site you visit.</Typography>} />
                </ListItem>

                <ListItem disablePadding alignItems="flex-start" sx={{ pt: 4 }}>
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">14</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Personal Data from Minors and Other Individuals</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">14.1</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">We are not intended to extend to, nor do we knowingly collect personal information of, anyone under the age of eighteen (18). As a parent or legal guardian, you are under the obligation to not allow a minor (individuals under eighteen (18) years of age) under your care to submit personal data to us in their own capacity. In the event that such personal data is provided to us, you hereby consent to the processing of the minor’s personal data and personally accept and agree to be bound by this Policy and take responsibility for his or her actions.</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">14.2</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">In some circumstances where you may have provided personal data relating to other individuals (such as your spouse, family members, friends etc), in such circumstances you represent and warrant that you are authorised to provide their personal data to us and you have obtained their consent for their personal data be processed and used in the manner as set forth in this Policy.</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">14.3</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">If we become aware that we have inadvertently collected personal data from anyone under the age of eighteen (18) without verification of parental consent, we will take steps to remove that information from our servers.</Typography>} />
                </ListItem>

                <ListItem disablePadding alignItems="flex-start" sx={{ pt: 4 }}>
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">15</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Enquiries or Complaints</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2">15.1</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">Should you have any queries, concerns or complaints in relation to this Policy or how we handle your personal data, please do not hesitate to contact us.</Typography>} />
                </ListItem>

                <ListItem disablePadding alignItems="flex-start" sx={{ pt: 4 }}>
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="subtitle2">16</Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">Amendment</Typography>} />
                </ListItem>
                <ListItem disablePadding alignItems="flex-start">
                    <ListItemIcon sx={{ mt: 0 }}>
                        <ListItemText primary={<Typography variant="body2"></Typography>} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">We shall have the right to modify, update or amend the terms of this Policy at any time, and from time to time by placing the updated Policy on our website, which shall be effective immediately. By continuing to use our website following the modifications, updates or amendments to this Policy, you acknowledge that such actions shall signify your acceptance of such modifications, updates or amendments.</Typography>} />
                </ListItem>


            </List>

        </Container>


        <MyFooter />
    </>;
}

export default PrivacyPage;