import React, { PropsWithChildren } from "react"
import { useNavigate } from "react-router-dom";
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { globalStyles, joinSx } from "../utils/style";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_productCategory, ProductImg_logoEcholight, ProductImg_logoEsteve, ProductImg_logoEverpharma, ProductImg_logoImed, ProductImg_logoJacobson, ProductImg_logoLotus, ProductImg_logoPharmathen, ProductImg_logoSerb, } from "src/assets";
import Paths from "../utils/Paths";


const products = [
    {
        title: "Pharmaceutical",
        sub: [
            // {
            //     title: "SciGen", img: ProductImg_logoSciGen,
            //     href: Paths.Products_sciGen
            // },
            {
                title: "Lotus Pharma", img: ProductImg_logoLotus,
                href: Paths.Products_lotus
            },
            {
                title: "Esteve", img: ProductImg_logoEsteve,
                href: "#"
            },
            {
                title: "Pharmathen", img: ProductImg_logoPharmathen,
                href: "#"
            },
            {
                title: "Everpharma", img: ProductImg_logoEverpharma,
                href: "#",
                target: "_blank"
            },
            {
                title: "Serb", img: ProductImg_logoSerb,
                // href: Paths.Brand_serb,
                href: "#",
                target: "_blank"
            },
            {
                title: "Jacobson", img: ProductImg_logoJacobson,
                // href: Paths.Brand_jacobson,
                href: "#",
                target: "_blank"
            }
        ],
    },
    {
        title: "Medical Device",
        sub: [
            {
                title: "i+Med", img: ProductImg_logoImed,
                // href: Paths.Brand_iMed,
                href: "#",
                target: "_blank"
            },
            {
                title: "Echolight", img: ProductImg_logoEcholight,
                // href: Paths.Brand_echolight,
                href: "#",
                target: "_blank"
            }
        ],
    }
]
interface SubItem {
    title: string;
    img: string;
    href: string;
    target?: string;
}
export interface ProductsGroupProps extends PropsWithChildren {
    sub: SubItem[];
}
const ProductsGroup: React.FC<ProductsGroupProps> = (props) => {
    const { sub } = props;
    const navigate = useNavigate();

    return <Box py={2}>
        <Grid container pb={5}>
            {
                sub.map((item) => (
                    <Grid item xs={6} md={3} textAlign="center" pb={8}>
                        <Box px={2} minHeight="100px" display="flex" alignItems='center'>
                            <img width="100%" src={item.img} alt="" />
                        </Box>
                        <Typography variant="subtitle2">{item.title}</Typography>
                        {(item.href !== "#" && item.href !== "") && <Button variant="contained"
                            onClick={() => {
                                (item.target)? window.open(`${item.href}`, `${item.target}`) : navigate(`${item.href}`)
                            }}
                            color="warning"
                            sx={joinSx(globalStyles.commonButton, { mt: 3 })}>
                            Read More
                        </Button>}
                    </Grid>
                ))
            }
        </Grid>
    </Box>;
}
export interface ProductsPageProps extends PropsWithChildren {

}
const ProductsPage: React.FC<ProductsPageProps> = (props) => {

    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_productCategory}>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Our Products</Typography>
        </TopHeaderBanner>
        <Container sx={{ py: 4 }}>
            {
                products.map((prod) => (
                    <>
                        <Typography variant="h5">{prod.title}</Typography>
                        <ProductsGroup sub={prod.sub} />
                    </>
                ))
            }
        </Container>


        <MyFooter />
    </>;
}

export default ProductsPage;