import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { Button, Container, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_salesMarketing } from "src/assets";
import { useNavigate } from "react-router-dom";
import Paths from "../utils/Paths";
import { ChevronLeft } from "@mui/icons-material";

export interface Services_salesMarketingProps extends PropsWithChildren {

}
const ServicesSalesMarketingPage: React.FC<Services_salesMarketingProps> = (props) => {
    const navigate = useNavigate();

    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_salesMarketing}></TopHeaderBanner>
        <Container sx={{ pt: 4, pb: 8 }}>
            <Button sx={{ color: "GrayText", mb: 3, pl: 0 }} size="small" onClick={() => { navigate(Paths.ProductServices) }} startIcon={<ChevronLeft />}>Back</Button>

            <Typography variant="h5" pb={2}>Sales and Marketing</Typography>
            <Typography variant="body1" pb={2}>
                <Typography component="p" pb={2}>
                    Aside from warehousing and distribution, Teraju Pharma includes <Typography color="primary" component="span" variant="subtitle1">a team of dedicated salespeople</Typography> that are trained and highly qualified.
                </Typography>
                <Typography component="p">
                    These aspects are additionally covered by our marketing team where <Typography color="primary" component="span" variant="subtitle1">branding</Typography> takes place. We satisfy our customers by providing the best products in the market for patients & consumers to benefit.
                </Typography>
            </Typography>
            
        </Container>


        <MyFooter />
    </>;
}

export default ServicesSalesMarketingPage;