import { LinkedIn } from "@mui/icons-material";
import { Box, Container, Grid, IconButton, Link, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react"
import Paths, { makeRelativePath } from "../utils/Paths";
import { logo_ecovadis, logo_smw2023 } from "src/assets";
import { createStyles } from "../utils/style";

const styles = createStyles({
    "ecoAwardLogo" : {
        width:{
            md:"80%",
            xs:"70%"
        }
    },
    "smeAwardLogo" : {
        width:"100%"
    },
});
export interface FooterProps extends PropsWithChildren {

}
const MyFooter: React.FC<FooterProps> = (props) => {
    const logo2nd = require('../../assets/images/logo-2nd.png');
    const p = makeRelativePath(Paths.Index);

    return <Box sx={{ backgroundColor: "#DCDCDC" }} >
        <Container sx={{ py: 4 }}>
            <Grid container>
                <Grid item xs={12} md={3} pb={3}>
                    <img src={logo2nd} width="100px" />
                </Grid>

                <Grid item xs={12} md={3} >
                    <Link display="block" href={p(Paths.NewsListing)} underline="none" pb={2}><Typography variant="body2" color="#333">News & Media</Typography></Link>
                    <Link display="block" href={p(Paths.Contact)} underline="none" pb={2}><Typography variant="body2" color="#333">Contact Us</Typography></Link>
                    <Link display="block" href={p(Paths.Career)} underline="none" pb={2}><Typography variant="body2" color="#333">Careers</Typography></Link>
                </Grid>

                <Grid item xs={12} md={3} >
                    <Link display="block" href={p(Paths.TNC)} underline="none" pb={2}><Typography variant="body2" color="#333">Terms of Use</Typography></Link>
                    <Link display="block" href={p(Paths.Privacy)} underline="none" pb={2}><Typography variant="body2" color="#333">Privacy Policy</Typography></Link>
                    <Link display="block" href={p(Paths.Cookie)} underline="none" pb={2}><Typography variant="body2" color="#333">Cookie</Typography></Link>
                </Grid>
                <Grid item xs={12} md={3} >
                    <Grid container alignItems="center">
                        <Grid item md={6} textAlign='center'>
                            <Box component='img' src={logo_smw2023} sx={styles.smeAwardLogo}></Box>
                        </Grid>
                        <Grid item md={6} textAlign='center'>
                            <Box component='img' src={logo_ecovadis} sx={styles.ecoAwardLogo}></Box>
                        </Grid>
                    </Grid>
                    
                    
                </Grid>

            </Grid>
        </Container>
        <Box sx={{ backgroundColor: "#3F3F3F" }} py={1}>
            <Container>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Box color="white" display="flex" alignItems="center" justifyContent={{ xs: "center", md: "left" }} height="100%">
                            <Typography variant="body2" textAlign="center">© Copyright 2023 Teraju Pharma. All Rights Reserved.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} textAlign={{ xs: "center", md: "right" }}>
                        <IconButton onClick={() => {window.open("https://www.linkedin.com/company/teraju-pharma-malaysia/", '_blank');}}>
                            <LinkedIn fontSize="large" color="primary" />
                        </IconButton>
                        {/* <IconButton>
                            <Facebook fontSize="large" color="primary" />
                        </IconButton>
                        <IconButton>
                            <Instagram fontSize="large" color="primary" />
                        </IconButton> */}
                    </Grid>
                </Grid>
                <Box></Box>
            </Container>
        </Box>
    </Box>;
}

export default MyFooter;