import React, { PropsWithChildren, useEffect, useState } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { createStyles, globalStyles, joinSx } from "../utils/style";
import { Box, Container, Divider, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_news } from "src/assets";
import { getNewsAPI } from "../services/api";
import { useLocation } from "react-router-dom";

const styles = createStyles({
    title: { fontFamily: "Akrobat" },
    name: { textTransform: "capitalize" }
})

interface NewsProps extends PropsWithChildren {
    theNews: NewsAPIResponse;
    isLast: boolean;
}
const NewsComp: React.FC<NewsProps> = (props) => {
    const { theNews, isLast } = props;

    return <>
        <Box display="flex" alignItems="start" id={`news${theNews.ID}`}>
            <Box component="img" src={theNews.images && theNews.images[0].image} alt="" width="30%" pr={2} />
            <Box flex={2}>
                {!!theNews.date && <Typography variant="body2" color="secondary" py={1} sx={styles.name}>
                    {theNews.date}
                </Typography>}
                {!!theNews.title && <Typography variant="h5" py={1}>
                    {theNews.title}
                </Typography>}
                <Typography whiteSpace="pre-line" pb={3}>
                    {theNews.content}
                </Typography>
            </Box>
        </Box>
        {!isLast && <Divider sx={{ my: 2 }} />}
    </>;
}

export interface NewsAPIResponse {
    ID: string;
    content: string;
    createBy: string | null;
    createTime: string;
    date: string;
    enable: string;
    images: [{
        image: string;
        type: string;
    }];
    summary: string;
    title: string;
    updateBy: string | null;
    updateTime: string;
}
export interface NewsListingProps extends PropsWithChildren {

}
const NewsListingPage: React.FC<NewsListingProps> = (props) => {

    const location = useLocation();
    const [newNewsListing, setNewNewsListing] = useState<NewsAPIResponse[]>();
    const fetchNews = async () => {
        try {
            const res = await getNewsAPI();
            setNewNewsListing(res);
        }
        catch (error) {
            console.log("error")
        }
    }

    useEffect(() => {
        fetchNews();
    }, [])

    useEffect(() => {
        const scrollToTarget = () => {
            if (location.hash) {
                setTimeout(() => {
                    const targetElement = document.getElementById(location.hash.substring(1));
                    // console.log("targetElement", targetElement, location.hash.substring(1))
                    if (targetElement) {
                        targetElement.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 100)
            }
        };

        scrollToTarget();
    }, [location]);


    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_news}>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>News &</Typography>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Media</Typography>
        </TopHeaderBanner>
        <Container sx={{ py: 4 }}>
            <Typography variant="h5" pb={2}>News & Media</Typography>
            <Divider sx={joinSx(globalStyles.contentDivider, { width: "20%", mb: 5 })} />
            <Box>
                {newNewsListing && newNewsListing.map((news, index) => (
                    <NewsComp theNews={news} key={index} isLast={index === newNewsListing.length - 1} />
                ))}
            </Box>

        </Container>


        <MyFooter />
    </>;
}

export default NewsListingPage;