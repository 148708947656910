import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { createStyles } from "../utils/style";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_globalDrugSourcing, } from "src/assets";
import { useNavigate } from "react-router-dom";
import Paths from "../utils/Paths";
import { ChevronLeft } from "@mui/icons-material";

const styles = createStyles({
    allColumn: { borderRadius: "10px", overflow: "hidden" },
    columnTitle: {
        textTransform: "capitalize",
        backgroundColor: "primary.main",
        color: "white",
        textAlign: "center",
        p: 1
    },
    columnContent: { backgroundColor: "#eee", textAlign: "center", p: 2 }
})


export interface Services_globalDrugSourcingProps extends PropsWithChildren {

}
const ServicesGlobalDrugSourcingPage: React.FC<Services_globalDrugSourcingProps> = (props) => {
    const navigate = useNavigate();

    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_globalDrugSourcing}></TopHeaderBanner>
        <Container sx={{ pt: 4, pb: 8 }}>
            <Button sx={{ color: "GrayText", mb: 3, pl:0 }} size="small" onClick={() => { navigate(Paths.ProductServices) }} startIcon={<ChevronLeft />}>Back</Button>

            <Typography variant="h5" pb={2}>Global Drug Sourcing</Typography>
            <Typography variant="body1" pb={2}>
                Sourcing medicines from multinational companies like Scigen, Ferrer, Dipharma, and United Therapeutics worldwide. Our success is shown in providing <Typography color="primary" component="span" variant="subtitle1">reliable solutions</Typography>, including influenza drugs during shortages, Rabies vaccines during outbreaks, and importing COVID-19 items on an emergency basis. Committed to reliable and timely services in dire situations.
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.allColumn}>
                        <Typography variant="subtitle1" sx={styles.columnTitle}>orphan drug sourcing</Typography>
                        <Typography variant="body1" sx={styles.columnContent}>
                            We source and provide medications for rare and orphan diseases, improving lives by filling healthcare gaps. <br/><Typography color="primary" component="span" variant="subtitle1">Access to drugs</Typography> like Sapropterin, Carglumic Acid, Mitotane, and Metyrapone is crucial for patients in need.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.allColumn}>
                        <Typography variant="subtitle1" sx={styles.columnTitle}>named patient importation</Typography>
                        <Typography variant="body1" sx={styles.columnContent}>
                            Our Named Patient Importation service sources <br/><Typography color="primary" component="span" variant="subtitle1">non-registered drugs</Typography>, obtains import permits, and provides patient-specific supply. Ensuring access to necessary medications for optimal treatment.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

        </Container>


        <MyFooter />
    </>;
}

export default ServicesGlobalDrugSourcingPage;