import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { createStyles, globalStyles, joinSx } from "../utils/style";
import { Box, Container, Divider, Typography, useTheme } from "@mui/material";
import MyFooter from "../components/footer";
import Slider from "react-slick"; //https://github.com/akiran/react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Banner_history, HistoryImg_2015, HistoryImg_2016, HistoryImg_2017, HistoryImg_2019, HistoryImg_2020, HistoryImg_2021, HistoryImg_2022, HistoryImg_2023, HistoryImg_2024 } from "src/assets";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const styles = createStyles({
    allSlide: {
        // backgroundColor: "rgba(1, 1, 1, 0.1)",
        textAlign: "center",
        "ul": {
            pr: "40px",
            textAlign: "justify"
        }
    }
})
const historys = [
    {
        img: HistoryImg_2015,
        points: [
            "Teraju Pharma began with wholesale trading and retail before expanding into other areas of pharmaceuticals."
        ]
    },
    {
        img: HistoryImg_2016,
        points: [
            "Started named patient importation with MOH before expanding services to private hospitals.",
            "First product was Rifaximin for Hospital Selayang, followed by Dasatinib for UMMC and Gleneagles KL"
        ]
    },
    {
        img: HistoryImg_2017,
        points: [
            "Won national tender (Hospital KL) for rare disease treatment with Sapropterin (Diterin) from Dipharma"
        ]
    },
    {
        img: HistoryImg_2019,
        points: [
            "Teraju Pharma became the distribution partner of Zometa (from Scigen)",
            "Won another national tender for rare disease treatment"
        ]
    },
    {
        img: HistoryImg_2020,
        points: [
            "Was the prime importer for Covid products for MOH",
            "Involved with distribution and supply management of Covid rare packs with government-linked companies (GLC) like KTMB, Johor Port, NIH",
            "Implemented emergency importation for national out-of-stock influenze vaccines from Australia, Singapore, and Hong Kong (Sanofi and Abbott)"
        ]
    },
    {
        img: HistoryImg_2021,
        points: [
            "Further named patient export to Singapore, Hong Kong, and the United Kingdom",
            "Exported products were for various uses i.e. oncology and primary care"
        ]
    },
    {
        img: HistoryImg_2022,
        points: [
            "TP Care (subsidiary of Teraju Pharma) partnered with PM Care in an exclusive supply agreement",
            "Continued to extend services to private and local corporations such as Bank Negara, Bank Islam, Schulumnerger Ltd, and more",
            "Gastropanel (screening test) from Biohit (Finland) was also approved"
        ]
    },
    {
        img: HistoryImg_2023,
        points: [
            "Plans to launch a series of new drugs and nutraceuticals",
            'Placed Bronze in Sustainability Rating Ecovadis 2023',
            'Ranked in the top 100 in SME Awards 2023 for Fast Moving Companies'
        ]
    },
    {
        img: HistoryImg_2024,
        points: [
            "Planning to launch a series of new drug, nutraceuticals and medical devices",
            
        ]
    }
]

export interface HistoryItemProps extends PropsWithChildren {
    img: string;
    points: string[];
}
const HistoryItem: React.FC<HistoryItemProps> = (props) => {
    const { img, points } = props;


    return (
        <Box sx={styles.allSlide}>
            <Box component="img" src={img} alt="" width={{ xs: "150px", md: "200px" }} mx="auto" />
            <ul>
                {points.map((point, index) => (
                    <Typography component="li" key={index}>{point}</Typography>
                ))}
            </ul>
        </Box>
    );
}

export interface HistoryPageProps extends PropsWithChildren {

}
const HistoryPage: React.FC<HistoryPageProps> = (props) => {

    const theme = useTheme();

    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_history}>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Our History</Typography>
        </TopHeaderBanner>
        <Container sx={{ p: 6 }}>
            <Typography textAlign="center">
                Teraju Pharma Sdn Bhd. started its journey in 2015 with a clear mission to provide the local and regional community access to great products and services. By sourcing medicines from trusted local and international suppliers, Teraju Pharma has developed a reputation for delivering innovative products and solutions to customers. Through our commitment to innovation and sustainability, we pride ourselves on being leaders in accessible pharmaceutical supply-chain solutions in the Southeast Asian region.
            </Typography>
            <Divider sx={joinSx(globalStyles.contentDivider, { width: "20%", my: 4, mx: "auto" })} />

            <Typography variant="h5" textAlign="center" py={4}>Performance Milestones</Typography>

            <Box position="relative" pb={4} >
                <Box position="absolute" width="100%" top="6px">
                    <Divider sx={{ position: "relative", top: "13.5px", borderColor: "black", borderWidth: "1.5px" }} />
                    <Box overflow="hidden" position="relative" sx={{ display: 'flex', justifyContent: 'flex-end' }}><ArrowForwardIosIcon sx={{ left: "7px", position: "relative" }} /></Box>
                </Box>
                <style>
                    {`
                        .slick-dots li.slick-active button:before {color: ${theme.palette.warning.main};}
                        .slick-dots li button:before {font-size:12px; }
                        .slick-prev, .slick-next {color: ${theme.palette.grey[500]}}
                        .slick-prev:hover, .slick-next:hover {color:${theme.palette.grey[700]}}
                    `}
                </style>
                <Slider {...{
                    dots: false,
                    infinite: false,
                    arrows: true,
                    speed: 500,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    prevArrow: <ArrowBackIosIcon />,
                    nextArrow: <ArrowForwardIosIcon />,
                    responsive: [
                        {
                            breakpoint: 450,
                            settings: { slidesToShow: 1, slidesToScroll: 1, },
                        },
                    ],
                }}>
                    {historys.map((history, index) => (
                        <Box><HistoryItem img={history.img} points={history.points} key={index} /></Box>
                    ))}
                </Slider>
            </Box>
        </Container>


        <MyFooter />
    </>;
}

export default HistoryPage;