import React, { useEffect } from 'react';
import { createStyles } from '../utils/style';
import { Box, IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


const styles = createStyles({
    "stickyWhatsapp": {
        position: "fixed",
        bottom: {
            md:8, xs:140
        }, 
        right: 8,
        p: {
            md:0.5, xs:1
        },
        zIndex: 11000,
        cursor: "pointer",
        // display:{md:'unset', xs:'none'},
        backgroundColor:"green",
        borderRadius:10,
        boxShadow:4
    },
    "icoBtn":{
        p:0.5,
    },
    "whatsappIcon": {
        fontSize: {
            md:35, xs:60
        },
        color:"white"
    }
})
export const StickyWhatsapp = () => {
    
    useEffect(() => {
        
    }, []);

    const onWhatsappClick = () => {
        window.open('https://wa.me/601116288763', '_blank');
    };

    return (
        <Box sx={styles.stickyWhatsapp}>
            <IconButton onClick={onWhatsappClick} sx={styles.icoBtn}>
                <WhatsAppIcon sx={styles.whatsappIcon} />
            </IconButton>
        </Box>
    );
};
