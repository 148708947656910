const Paths = {
  Index: "/",
  Home: "/",
  Contact: "/contact-us",
  AboutUs: "/about-us",
  OurHistory: "/history",
  Awards: "/awards",
  OurTeam: "/our-team",
  ProductServices: "/services",
  Services_GlobalDrugSourcing: "/services/global-drug-sourcing",
  Services_PharmaBenefitMgmt: "/services/pharma-benefit-management",
  Services_WarehouseDistribution: "/services/warehouse-distribution",
  Services_SalesMarketing: "/services/sales-marketing",
  Products: "/products",
  Products_sciGen: "/products/scigen",
  Products_lotus: "/products/lotus",
  Career: "/careers",
  CSR: "/csr",
  ResourceLinks: "/resource-links",
  NewsListing: "/news",
  TNC: "/terms",
  Privacy: "/privacy",
  Cookie: "/cookie-privacy",
  NotFound: "/page/notfound",
  
  Brand_serb:"https://serb.com",
  Brand_jacobson:"https://www.jacobsonpharma.com",
  Brand_iMed:"https://imasmed.com/en/",
  Brand_echolight:"https://www.echolightmedical.com/en/",
}

export const makeRelativePath = (rootPath: string) => {
  return (path: string, suffix = "") => path.replace(rootPath, "").concat(suffix);
}

export default Paths
