import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { globalStyles } from "../utils/style";
import { Container, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_aboutUs } from "src/assets";

export interface CookiePageProps extends PropsWithChildren {

}
const CookiePage: React.FC<CookiePageProps> = (props) => {

    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_aboutUs}>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Cookie Policy for Teraju Pharma</Typography>
        </TopHeaderBanner>
        <Container sx={{ py: 4 }}>
            <Typography variant="body2" textAlign="justify">
                <p>This is the Cookie Policy for Teraju Pharma, accessible from terajupharma.com</p>

                <Typography component="p" pt={4} variant="subtitle2">What Are Cookies</Typography>
                <p>
                    As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the site's functionality.
                </p>

                <Typography component="p" pt={4} variant="subtitle2">How We Use Cookies</Typography>
                <p>
                    We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.
                </p>

                <Typography component="p" pt={4} variant="subtitle2">Disabling Cookies</Typography>
                <p>
                    You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of this site. Therefore it is recommended that you do not disable cookies.
                </p>

                <Typography component="p" pt={4} variant="subtitle2">The Cookies We Set</Typography>
                <p>
                    Forms related cookies:<br />
                    When you submit data through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence.
                </p>
                <p>
                    Site preferences cookies:<br />
                    In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page that is affected by your preferences.
                </p>

                <Typography component="p" pt={4} variant="subtitle2">Third Party Cookies</Typography>
                <p>
                    In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.
                    <ul style={{ listStyleType: "lower-alpha" }}>
                        <li>This site uses Google Analytics which is one of the most widespread and trusted analytics solutions on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content. For more information on Google Analytics cookies, see the official Google Analytics page.</li>
                        <li>From time to time we test new features and make subtle changes to the way that the site is delivered. When we are still testing new features these cookies may be used to ensure that you receive a consistent experience whilst on the site whilst ensuring we understand which optimisations our users appreciate the most.</li>
                    </ul>
                    These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.
                </p>

                <Typography component="p" pt={4} variant="subtitle2">More Information</Typography>
                <p>
                    If you have any further questions, please contact us via the Contact Us form on https://terajupharma.com
                </p>
                
            </Typography>
        </Container>


        <MyFooter />
    </>;
}

export default CookiePage;