import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { Button, Container, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_warehouseDistribution } from "src/assets";
import { useNavigate } from "react-router-dom";
import Paths from "../utils/Paths";
import { ChevronLeft } from "@mui/icons-material";

export interface Services_warehouseDistributionProps extends PropsWithChildren {

}
const ServicesWarehouseDistributionPage: React.FC<Services_warehouseDistributionProps> = (props) => {
    const navigate = useNavigate();

    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_warehouseDistribution}></TopHeaderBanner>
        <Container sx={{ pt: 4, pb: 8 }}>
            <Button sx={{ color: "GrayText", mb: 3, pl: 0 }} size="small" onClick={() => { navigate(Paths.ProductServices) }} startIcon={<ChevronLeft />}>Back</Button>

            <Typography variant="h5" pb={2}>Warehouse and Distribution</Typography>
            <Typography variant="body1" pb={2}>
                <Typography component="p" pb={2}>
                    Our Warehouse and Distribution services prioritize safe handling, storing, and transportation of all products. We have achieved both GDP & GDPMD certifications and own 2 warehouses in Bukit Raja: 10,000 sqft and 8,000 sqft.
                </Typography>
                <Typography component="p">
                    This includes <Typography color="primary" component="span" variant="subtitle1">cold chain storage</Typography>, ensuring efficacy. Specializing in transportation services to maintain quality during delivery and stringent procedures to preserve quality at any stage in the supply process.
                </Typography>
            </Typography>

        </Container>


        <MyFooter />
    </>;
}

export default ServicesWarehouseDistributionPage;