// import { Facebook, Instagram, LinkedIn } from "@mui/icons-material";
import { Box, Button, Grid, TextField, Typography, CircularProgress } from "@mui/material";
import React, { PropsWithChildren, useState } from "react"
import { globalStyles } from "../utils/style";
import { FormSubmittedDialog } from "./formSubmittedDialog";

interface FormErrors {
    name?: string;
    email?: string;
    mobile?: string;
    message?: string;
}
export interface ContactFormProps extends PropsWithChildren {

}
const ContactForm: React.FC<ContactFormProps> = (props) => {

    const [formData, setFormData] = useState({
        name: "",
        mobile: "",
        email: "",
        message: ""
    });
    const [messageLength, setMessageLength] = useState(0);
    const [errors, setErrors] = useState<FormErrors>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [submitStatus, setSubmitStatus] = useState<boolean>(false);

    const isValidEmail = (email: string) => {
        // // Regular expression pattern for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const isValidMobileNumber = (mobileNumber: string) => {
        // Regular expression pattern for mobile number validation
        const mobileNumberRegex: RegExp = /^[0-9]+$/;
        return mobileNumberRegex.test(mobileNumber);
    }

    const validateForm = () => {
        const newErrors: FormErrors = {};
        if (formData.name.trim() === '') {
            newErrors.name = 'Name is required';
        }
        if (formData.mobile.trim() === '') {
            newErrors.mobile = 'Mobile number is required';
        } else if (!isValidMobileNumber(formData.mobile)) {
            newErrors.mobile = 'Invalid mobile number format';
        }

        if (formData.email.trim() === '') {
            newErrors.email = 'Email is required';
        } else if (!isValidEmail(formData.email)) {
            newErrors.email = 'Invalid email format';
        }

        if (formData.message.trim() === '') {
            newErrors.message = 'Message is required';
        }
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (validateForm()) {
            setIsLoading(true);
            try {
                await fetch('/new2023/phpmailer/send_email.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams(formData)
                })
                    .then(response => response.text())
                    .then(data => {
                        //const jsonStartIndex = data.indexOf('{'); // Find the starting index of the JSON portion
                        //const jsonResponse = data.slice(jsonStartIndex); // Extract the JSON portion

                        //const parsedResponse = JSON.parse(jsonResponse); // Parse the JSON response
                        // Handle the parsed response as needed
                        // console.log(parsedResponse) // OUTPUT: {message: "Email sent successfully", success : true}
                        setSubmitStatus(true);
                    })
                    .catch(error => {
                        setSubmitStatus(false);
                        // Handle any error that occurred during the request
                    });
                setIsLoading(false);

            } catch (error) {
                setIsLoading(false);
                setSubmitStatus(false);
            }
            setOpenDialog(true);
        }

    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target.name === "message") {
            setMessageLength(event.target.value.length);
        }

        setFormData({ ...formData, [event.target.name]: event.target.value });
    };
    const dialogOnClose = (status: boolean) => {
        setOpenDialog(status);
    }

    return (<form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <TextField
                    required fullWidth
                    name="name" label="Name" variant="filled"
                    sx={globalStyles.inputField}
                    onChange={handleChange}
                    error={errors.name ? true : false}
                    helperText={errors.name}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    required fullWidth
                    name="mobile" label="Mobile Number" variant="filled"
                    sx={globalStyles.inputField}
                    onChange={handleChange}
                    error={errors.mobile ? true : false}
                    helperText={errors.mobile}

                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    required fullWidth
                    name="email" label="Email Address" variant="filled"
                    sx={globalStyles.inputField}
                    onChange={handleChange}
                    error={errors.email ? true : false}
                    helperText={errors.email}

                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    multiline
                    helperText={
                        <Typography variant="caption" component="div" textAlign="right">
                            {messageLength}/200 characters
                        </Typography>
                    }
                    inputProps={{ maxLength: 200 }}
                    name="message"
                    rows={5}
                    label="Message"
                    variant="filled"
                    fullWidth
                    sx={globalStyles.inputField}
                    onChange={handleChange}
                    error={errors.message ? true : false}
                />
                <Box display="flex" justifyContent="center" py={4}>
                    <Button disabled={isLoading} startIcon={isLoading && <CircularProgress size="small" />} type="submit" variant="contained" color="warning" sx={{ px: 4 }}>Submit</Button>
                </Box>
            </Grid>
            <FormSubmittedDialog open={openDialog} status={submitStatus} close={dialogOnClose} />
        </Grid>
    </form>)
}

export default ContactForm;