import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { globalStyles, joinSx } from "../utils/style";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_aboutUs, GDPMD } from "src/assets";

export interface AboutUsProps extends PropsWithChildren {

}
const AboutUs: React.FC<AboutUsProps> = (props) => {

    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_aboutUs}>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>About Us</Typography>
        </TopHeaderBanner>
        <Container sx={{ py: 4 }}>
            <Typography textAlign="center">
                <Box component="p">Will-be leaders of innovative healthcare in Asia, we strive to meet the demands of our customers and partners. We, at Teraju Pharma, provide specialized pharmaceutical products and services to healthcare providers — be it local or regional.</Box>
                <Box component="p">We believe in perfection, and we promise this through our superior and innovative quality of services. However, we strongly value ethics and relationships. Our partnerships with trusted local and international suppliers ensure that you always get the best!</Box>
            </Typography>
        </Container>
        <Grid container>
            <Grid item xs={12} md={6} sx={{ backgroundColor: "primary.main" }}>
                <Container sx={{ py: 4, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <Box width={{ xs: "unset", xl: "70%" }}>
                        <Typography variant="h5" color="white" pb={2}>Our Vision</Typography>
                        <Divider sx={{ mb: 2, width: "40%" }} />
                        <Typography color="white">
                            <Box component="p" textAlign="justify">Teraju Pharma's vision is to provide equal opportunities in accessing innovative medicine and services to customers. A place where quality meets accessibility, increasing awareness and overall affordability in our communities.</Box>
                        </Typography>
                    </Box>
                </Container>
            </Grid>
            <Grid item xs={12} md={6} sx={{ backgroundColor: "secondary.main" }}>
                <Container sx={{ py: 4, }}>
                    <Box width={{ xs: "unset", xl: "70%" }}>
                        <Typography variant="h5" color="white" pb={2}>Our Mission</Typography>
                        <Divider sx={{ mb: 2, width: "40%" }} />
                        <Typography color="white" >
                            <Box component="p" textAlign="justify">Teraju Pharma's mission is to dedicate ourselves to a sustainable future that pioneers innovation. A world that provides fairer treatment to customers and patients alike.</Box>
                        </Typography>
                    </Box>
                </Container>
            </Grid>
        </Grid>
        <Container sx={{ pt: 4, pb: 10 }}>
            <Grid container>
                <Grid item xs={12} md={2}>
                    <Box height="100%" display="flex" alignItems="center"><Typography variant="h5">Core Values</Typography></Box>
                </Grid>
                <Divider orientation="vertical" flexItem sx={joinSx(globalStyles.contentDivider, { mx: 4, display: { xs: "none", md: "inherit" } })} />
                <Divider sx={joinSx(globalStyles.contentDivider, { width: "70%", my: 4, display: { xs: "block", md: "none" } })} />
                <Grid item xs={12} md={9}>
                    <Typography textAlign="justify">
                        <Typography component="p">
                            <strong>Adhocracy Culture:</strong>
                            <Typography component="p">Our culture fosters adaptability, creativity, innovation, and growth. Employees make informed decisions, collaborate, and solve problems to advance company success.</Typography>
                        </Typography>
                        <p>Yet, we also believe in our four core values:</p>
                        <ul>
                            <li><Box component="strong" color="secondary.main">INNOVATION:</Box> Encouraging ideas and actions that improve the well-being of others.</li>
                            <li><Box component="strong" color="secondary.main">IMPACT-DRIVEN:</Box> To create an impact that will better lives and our future generations.</li>
                            <li><Box component="strong" color="secondary.main">SUSTAINABLE:</Box> At Teraju Pharma, we make sustainability a priority.</li>
                            <li><Box component="strong" color="secondary.main">PASSIONATE:</Box> Making the world a better place through accessibility and genuine care.</li>
                        </ul>

                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{my:4}}/>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <Box sx={{borderRadius:3, marginRight:{md:3}, overflow:'hidden', display:'flex', marginBottom:{xs:3, }}}>
                        <img width="100%" src={GDPMD} alt="" />
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography textAlign="justify">
                        <Typography component="p">
                            <strong>GDP & GDPMD Certified</strong>
                            <Typography component="p">Our Warehouse and Distribution services boast GDP and GDPMD certifications, guaranteeing top-tier safety and quality. Packed with cold chain storage, specialized transportation, and meticulous quality control, we ensure your products are in the best hands every step of the way.</Typography>
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
            
        </Container>

        <MyFooter />
    </>;
}

export default AboutUs;