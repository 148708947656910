const API_BASE_URL = 'https://terajupharma.com/new2023';
// insert below into package.json for development
// "proxy": "http://terajupharma.com/new2023"


export const getNewsAPI = () => {
    return fetch(`${API_BASE_URL}/news_panel/API/`)
    // return fetch(`/news_panel/API/`)
        .then((response) => response.json())
        .catch((error) => {
            throw new Error(`Error fetching posts: ${error}`);
        });
};