import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { globalStyles } from "../utils/style";
import { Container, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_award } from "src/assets";


export interface AwardsPageProps extends PropsWithChildren {

}
const AwardsPage: React.FC<AwardsPageProps> = (props) => {

    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_award}>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Awards &</Typography>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Accolades</Typography>
        </TopHeaderBanner>
        <Container sx={{ pt:4, pb: 10 }}>
            <Typography textAlign="center">
            The prestigious recognition and awards are a reminder and motivation to Teraju Pharma as the leading Pharmaceutical provider in Asia. It gives us the confidence to support and push our purpose to greater heights.
            </Typography>

        </Container>


        <MyFooter />
    </>;
}

export default AwardsPage;