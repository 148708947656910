import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { createStyles, globalStyles, joinSx } from "../utils/style";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_team, Team1_max_low, Team2_foong_seing_heng, Team3_william, Team4_bryan, Team7_ilvin } from "src/assets";

const styles = createStyles({
    title: { fontFamily: "Akrobat", textTransform: "capitalize" },
    name: { textTransform: "capitalize" }
})
const teamMember = [
    {
        img: Team1_max_low,
        name: "max low jin ren",
        title: "Chairman of the Board",
        review: '"Embrace the unknown, take calculated risks, and innovate relentlessly. That\'s the mindset we encourage at Teraju Pharma to drive growth and make a positive impact in the world."'
    },
    {
        img: Team2_foong_seing_heng,
        name: "Foong Seong Heng",
        title: "Chief Executive Officer",
        review: '"Committed to bringing affordable and high-quality healthcare services to everyone in Asia. We can innovate and improve patient outcomes while remaining socially responsible and sustainable."'
    },
    {
        img: Team3_william,
        name: "William Cheah wei loon",
        title: "Chief Operations Officer",
        review: '"Innovation is at the heart of what we do at Teraju Pharma. We strive to be the leading company by embracing new technologies, providing exceptional service to our clients, and thinking outside the box to stand out in the market."'
    },
    {
        img: Team4_bryan,
        name: "Bryan wong kin lip",
        title: "Deputy Chief Executive Officer",
        review: '"By effectively communicating our values and initiatives, we can continue to build trust and make a positive impact in the healthcare industry."'
    },
    {
        img: Team7_ilvin,
        name: "Ilvin Goh",
        title: "Chief Financial Officer",
        review: '"Financial stability and transparency can empower Teraju Pharma as a leader in innovative solutions that will enhance patient care and drive growth for our communities."'
    },
    // {
    //     img: Team6_syukri,
    //     name: "Syukri Hadafi Hamdan",
    //     title: "Human Resource Director",
    //     review: '"Through innovative strategies and strong partnerships, we strive to provide accessible and affordable healthcare solutions to the communities we serve."'
    // }
]

export interface TeamMemberProps extends PropsWithChildren {
    member: {
        name: string;
        title: string;
        review: string;
        img: string;
    },
    isLast: boolean;
}
const TeamMemberComp: React.FC<TeamMemberProps> = (props) => {
    const { member, isLast } = props;

    return <>
        {/* <Box display="flex" alignItems="start">
            <Box component="img" src={member.img} alt="" maxWidth="35%" pr={2} />
            <Box flex={2}>
                <Typography variant="h5" color="secondary" py={1} sx={styles.name}>
                    {member.name}
                </Typography>
                <Typography variant="subtitle1" py={1} sx={styles.title}>
                    {member.title}
                </Typography>
                <Typography py={1}>
                    {member.review}
                </Typography>
            </Box>
        </Box> */}
        <Grid container>
            <Grid item xs={12} md={3} pr={2} pb={1} >
                <Box component="img" src={member.img} alt="" width="100%"/>
            </Grid>
            <Grid item xs={12} md={9} pb={1} >
                <Typography variant="h5" color="secondary" sx={styles.name}>
                    {member.name}
                </Typography>
                <Typography variant="subtitle1" py={1} sx={styles.title}>
                    {member.title}
                </Typography>
                <Typography py={1}>
                    {member.review}
                </Typography>
            </Grid>
        </Grid>
        {!isLast && <Divider sx={{ my: 2 }} />}
    </>;
}
export interface OurTeamProps extends PropsWithChildren {

}
const OurTeamPage: React.FC<OurTeamProps> = (props) => {

    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_team}>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Our Team</Typography>
        </TopHeaderBanner>
        <Container sx={{ py: 4 }}>
            <Typography variant="h5" pb={2}>Meet Our Team</Typography>
            <Divider sx={joinSx(globalStyles.contentDivider, { width: "20%", mb: 5 })} />
            <Box>
                {teamMember.map((member, index) => (
                    <TeamMemberComp member={member} key={index} isLast={index === teamMember.length - 1 ? true : false} />
                ))}
            </Box>

        </Container>


        <MyFooter />
    </>;
}

export default OurTeamPage;