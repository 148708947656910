import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { globalStyles } from "../utils/style";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_csr, Csr_rumahKids_img, Csr_YiXingEvent_img } from "src/assets";

export interface CSRPageProps extends PropsWithChildren {

}
const CSRPage: React.FC<CSRPageProps> = (props) => {

    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_csr}>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Corporate</Typography>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Social</Typography>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Responsibilities</Typography>
        </TopHeaderBanner>
        <Container sx={{ py: 4 }}>
            <Typography textAlign="justify">
                <p>Teraju Pharma emphasizes three important aspects as we become leaders of innovative pharmaceuticals in Asia:
                    <Typography color="primary">Environmental, Social, and Governance.</Typography></p>
                <p>
                    As environmental disruption continues to rise, we are committed to minimizing our carbon footprint through sustainable practices. Teraju Pharma further hopes to create job opportunities for the community by implementing this process.
                </p>
                <p>
                    Socially, Teraju Pharma have always prioritized giving back to society through numerous charitable events such as COVID-19 aid. We also continue to provide training for the inspired younger generation.
                </p>
                <p>
                    In terms of governance, we are proud to declare our practices of transparency and accountability in our operations. Teraju Pharma will always ensure its adherence to strict corporate SOP.
                </p>
            </Typography>
            <Divider sx={{my:3}} />
            <Grid container>
                <Grid item xs={12} md={4}>
                    <Box sx={{borderRadius:3, marginRight:{md:3}, overflow:'hidden', display:'flex', marginBottom:{xs:3, }}}>
                        <img width="100%" src={Csr_YiXingEvent_img} alt="" />
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography variant="h5" pb={2}>CSR activity at Yi Xing USJ Old Folks' Home</Typography>
                    <Typography variant="body1">
                        <Typography component="p" >
                            <p>Our team embarked on a heartwarming CSR activity at Yi Xing USJ Old Folks' Home, creating lasting memories with genuine smiles, laughter, and stories, while emphasizing the importance of elderly health through essential screenings. Sharing our CSR journey, we aim to inspire compassion and meaningful initiatives in others, encouraging them to undertake meaningful initiatives within their communities.</p>
                            <p>Collectively, we hold the belief that even the tiniest acts of kindness can have a meaningful impact.</p>
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{my:3}} />
            <Grid container>
                <Grid item xs={12} md={4}>
                        <Box sx={{borderRadius:3, marginRight:{md:3}, overflow:'hidden', display:'flex', marginBottom:{xs:3}}}>
                            <img width="100%" src={Csr_rumahKids_img} alt="" />
                        </Box>
                    </Grid>
                <Grid item xs={12} md={8}>
                    <Typography variant="h5" pb={2}>CSR venture to Rumah KIDS orphanage</Typography>
                    <Typography variant="body1" pb={2}>
                        <Typography component="p" pb={2}>
                            <p>Our recent CSR venture to Rumah KIDS orphanage was a resounding success. From shared laughter to heartwarming interactions, our team left a lasting impression on the children's lives. Through our commitment, we brought joy and support to these remarkable young hearts, creating cherished memories that will endure. As we reflect on this experience, we're reminded of the transformative power of compassion and community involvement.</p>
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
        </Container>

        <MyFooter />
    </>;
}

export default CSRPage;