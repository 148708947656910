import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { createStyles, globalStyles, joinSx } from '../utils/style';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Paths from '../utils/Paths';


const styles = createStyles({
    "cookiePrompt": {
        position: "fixed",
        bottom: 0, right: 0, left: 0,
        p: 1,
        zIndex: 10000,
        width: {md:"60%"}
    },
    "cookieInner": {
        px: 2, py: 4,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        textAlign: { xs: "center", md: "unset" },
        color: "white",
    }
})
export const CookiePrompt = () => {
    const [cookies, setCookie] = useCookies(['cookieAccepted', "cookieRejected"]);
    const [showPrompt, setShowPrompt] = useState(false);

    useEffect(() => {
        const cookieStatus = cookies.cookieAccepted === 'true' || cookies.cookieRejected === 'true';
        setShowPrompt(!cookieStatus);
    }, [cookies.cookieAccepted, cookies.cookieRejected]);

    const acceptCookie = () => {
        setCookie('cookieAccepted', 'true', { path: '/' });
        setShowPrompt(false);
    };
    const rejectCookie = () => {
        setCookie('cookieRejected', 'true', { path: '/' });
        setShowPrompt(false);
    };

    if (!showPrompt) {
        return null; // No need to render the prompt if it's not shown
    }

    return (
        <Box sx={styles.cookiePrompt}>
            <Box sx={styles.cookieInner}>
                <Typography component="p" pb={2}>Our website uses cookies to personalise content and ads, analyse traffic, and improve your browsing experience. By clicking 'Accept', you consent to the use of cookies and the processing of your personal data. Learn more in our <Link to={Paths.Privacy} style={{color:"unset"}}>privacy policy</Link> and <Link to={Paths.Cookie} style={{color:"unset"}}>cookie policy</Link>.</Typography>
                <Box>
                    <Button onClick={rejectCookie} variant="contained" sx={joinSx(globalStyles.commonButton, { backgroundColor: "#008E80", mr: 2 })}>Reject</Button>
                    <Button onClick={acceptCookie} variant="contained" sx={joinSx(globalStyles.commonButton, { backgroundColor: "#008E80" })}>Accept</Button>
                </Box>
            </Box>
        </Box>
    );
};
