import { Button, DialogProps, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface FormSubmittedDialogProps extends Omit<DialogProps, "open"> {
    open?: boolean;
    close?: (status: boolean) => void;
    status: boolean;
}
export const FormSubmittedDialog: React.FC<FormSubmittedDialogProps> = (props) => {
    const { status, close, open = false } = props;
    
    const handleClose = () => {
        close && close(false);
    };

    return (<>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Form Submission {status ? "Succeed" : "Failed"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {
                        status ? "Your submission has been sent successfully. Thank you" :
                            "Submission failed. Please try again later."
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}