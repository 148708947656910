import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { Container, Typography } from "@mui/material";
import { globalStyles } from "../utils/style";
import MyFooter from "../components/footer";
import { Banner_career } from "src/assets";
import CareerForm from "../components/careerForm";

export interface CareerPageProps extends PropsWithChildren {

}
const CareerPage: React.FC<CareerPageProps> = (props) => {
    
    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_career}>
            <Typography variant="h1" sx={globalStyles.bannerHeaderText}>Join Us</Typography>
        </TopHeaderBanner>

        <Container sx={{ py: 4 }}>
            <Typography variant="body2" pb={4} textAlign="center">
                Are you a dedicated person wanting to make a change in the future of innovative pharmaceuticals? Teraju Pharma is always looking to expand our family. We want to reach out to everyone, and provide them the healthcare service they need. If you are that person, fill up the form below!
            </Typography>
            <Typography variant="h5" pb={4}>Application Form</Typography>
            
            <CareerForm />
        </Container>
        <MyFooter />
    </>;
}

export default CareerPage;