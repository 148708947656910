import { ThemeOptions } from '@mui/material';

export const baseThemeOptions: ThemeOptions = { 
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          letterSpacing: 0
        }
      }
    },
    MuiMenuItem: {
      defaultProps: {
        // disableElevation: true
      },
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {
            // fontSize: 12,
            fontSize:"1rem"
          },
          "&:hover": {
            backgroundColor: "#FFBE05",
            color: "white",
            "& .MuiTypography-root": {
              // fontSize: 12,
              fontWeight:700
            }
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        // disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          borderRadius: "50px",
          fontSize:"1rem"
        }
        // sizeSmall: {
        //   padding: '6px 16px'
        // },
        // sizeMedium: {
        //   padding: '8px 20px'
        // },
        // sizeLarge: {
        //   padding: '11px 24px'
        // },
        // textSizeSmall: {
        //   padding: '7px 12px'
        // },
        // textSizeMedium: {
        //   padding: '9px 16px'
        // },
        // textSizeLarge: {
        //   padding: '12px 16px'
        // }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          paddingRight:"10px"
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          // padding: '16px 24px'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          // padding: '32px 24px',
          '&:last-child': {
            paddingBottom: '32px'
          }
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        },
        subheaderTypographyProps: {
          variant: 'body2'
        }
      },
      styleOverrides: {
        root: {
          // padding: '32px 24px'
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary'
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          // fontWeight: 600,
          // fontSize: '12px',
          // borderRadius: '8px',
          // padding: '4px 8px',
          // '& .MuiChip-label': {
          //   paddingLeft: '8px',
          //   paddingRight: '4px'
          // },
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          // fontSize: "20px",
          // lineHeight: 1.5,
        },
      },
    },
    MuiDrawer: {},
    MuiIconButton: {
      styleOverrides: {
        root: {
          // borderRadius: 8,
          // padding: 8
        },
        sizeSmall: {
          // padding: 4
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          // borderRadius: 3,
          // overflow: 'hidden'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          // marginRight: '16px',
          // '&.MuiListItemIcon-root': {
          //   minWidth: 'unset'
          // }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // transform: "translate(24px, -9px) scale(0.75)"
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // border: "2px solid #DADEE5",
        },
        input: {
          // fontWeight: 500
        },
        notchedOutline: {
          // padding: "0 18px",
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          
        }
      }
    },
    MuiPopover: {
      defaultProps: {
        elevation: 16
      }
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary'
      }
    },
    MuiSwitch: {
      defaultProps: {
        color: 'primary'
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        // badge: {
        //   fontWeight: 600,
        // }
      }
    },
  },
  direction: 'ltr',
  shape: {
    borderRadius: 8
  },
  typography: {
    fontFamily: 'Libre Franklin',
    button: {
      fontWeight: 700
    },
    body1: {
      fontSize: '1.2rem',//'1rem',
      fontWeight: 400,
      lineHeight: 1.7
    },
    body2: {
      fontSize: '1rem',//'0.875rem',
      fontWeight: 400,
      lineHeight: 1.77
    },
    subtitle1: {
      fontSize: '1.2rem',//'1rem',
      fontWeight: 600,
      lineHeight: 1.95
    },
    subtitle2: {
      fontSize: '1rem',//'0.875rem',
      fontWeight: 600,
      lineHeight: 1.77
    },
    overline: {
      fontSize: '1rem',//'0.75rem',
      fontWeight: 600,
      letterSpacing: '0.5px',
      lineHeight: 2.7,
      textTransform: 'uppercase'
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.86
    },
    h1: {
      fontWeight: 700,
      fontSize: '3.5rem',
      lineHeight: 1.275,
      fontFamily:"Akrobat"
    },
    h2: {
      fontWeight: 700,
      fontSize: '3rem',
      lineHeight: 1.375,
      fontFamily:"Akrobat"
    },
    h3: {
      fontWeight: 700,
      fontSize: '2.25rem',
      lineHeight: 1.175,
      fontFamily:"Akrobat"
    },
    h4: {
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: 1.375,
      fontFamily:"Akrobat"
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: 1.375
    },
    h6: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: 1.375
    }
  },
  zIndex: {
    appBar: 1100,
    drawer: 1200
  }
};
