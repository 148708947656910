import './App.css';
import { ThemeProvider } from "@mui/material/styles";
import React, { PropsWithChildren, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { createTheme } from './main/theme';
import HomePage from './main/views/Home';
import Paths, { makeRelativePath } from './main/utils/Paths';
import ContactPage from './main/views/Contact';
import AboutUs from './main/views/AboutUs';
import HistoryPage from './main/views/OurHistory';
import AwardsPage from './main/views/Awards';
import CSRPage from './main/views/CSR';
import OurTeamPage from './main/views/OurTeam';
import ProductServicesPage from './main/views/ProductServices';
import CareerPage from './main/views/Career';
import ProductsPage from './main/views/Products';
import SciGenPage from './main/views/Products_scigen';
import ServicesGlobalDrugSourcingPage from './main/views/Services_globalDrugSourcing';
import ServicesPharmaBenefitMgmtPage from './main/views/Services_pharmaBenefitMgmt';
import ServicesWarehouseDistributionPage from './main/views/Services_warehouseDistribution';
import ServicesSalesMarketingPage from './main/views/Services_salesMarketing';
import ResourceLinks from './main/views/Resource';
import NewsListingPage from './main/views/NewsListing';
import TNCPage from './main/views/tnc';
import PrivacyPage from './main/views/privacy';
import CookiePage from './main/views/cookie';
import { PageView, initGA } from './main/components/ga';
import { Helmet } from 'react-helmet';
import LotusPage from './main/views/Products_lotus';

export interface AppProps extends PropsWithChildren {

}
const App: React.FC<AppProps> = (props) => {
  const location = useLocation();

  const theme = createTheme({ mode: "light" });
  const p = makeRelativePath(Paths.Index);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") initGA(); // Initialize Google Analytics
    PageView(); // Initial pageview

  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta name="description" content="Teraju Pharma is a reliable healthcare service provider for accessible innovation through ethical partnerships, superior services, and sustainable solutions" />
      </Helmet>
      <Routes>
        <Route path={`${Paths.Home}/*`} element={<HomePage />} />
        <Route path={p(Paths.AboutUs)} element={<AboutUs />} />
        <Route path={p(Paths.OurHistory)} element={<HistoryPage />} />
        <Route path={p(Paths.Awards)} element={<AwardsPage />} />
        <Route path={p(Paths.CSR)} element={<CSRPage />} />
        <Route path={p(Paths.OurTeam)} element={<OurTeamPage />} />
        <Route path={p(Paths.ProductServices)} element={<ProductServicesPage />} />
        <Route path={p(Paths.Services_GlobalDrugSourcing)} element={<ServicesGlobalDrugSourcingPage />} />
        <Route path={p(Paths.Services_PharmaBenefitMgmt)} element={<ServicesPharmaBenefitMgmtPage />} />
        <Route path={p(Paths.Services_WarehouseDistribution)} element={<ServicesWarehouseDistributionPage />} />
        <Route path={p(Paths.Services_SalesMarketing)} element={<ServicesSalesMarketingPage />} />
        <Route path={p(Paths.Products)} element={<ProductsPage />} />
        <Route path={p(Paths.Products_sciGen)} element={<SciGenPage />} />
        <Route path={p(Paths.Products_lotus)} element={<LotusPage />} />
        <Route path={p(Paths.ResourceLinks)} element={<ResourceLinks />} />
        <Route path={p(Paths.Career)} element={<CareerPage />} />
        <Route path={p(Paths.NewsListing)} element={<NewsListingPage />} />
        <Route path={p(Paths.TNC)} element={<TNCPage />} />
        <Route path={p(Paths.Privacy)} element={<PrivacyPage />} />
        <Route path={p(Paths.Cookie)} element={<CookiePage />} />
        <Route path={p(Paths.Contact)} element={<ContactPage />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
