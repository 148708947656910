import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { Button, Container, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_pharmaBenefitMgmt } from "src/assets";
import { useNavigate } from "react-router-dom";
import Paths from "../utils/Paths";
import { ChevronLeft } from "@mui/icons-material";


export interface Services_pharmaBenefitMgmtProps extends PropsWithChildren {

}
const ServicesPharmaBenefitMgmtPage: React.FC<Services_pharmaBenefitMgmtProps> = (props) => {
    const navigate = useNavigate();

    return <>
        <TopNavigation />

        <TopHeaderBanner image={Banner_pharmaBenefitMgmt}></TopHeaderBanner>
        <Container sx={{ pt: 4, pb: 8 }}>
            <Button sx={{ color: "GrayText", mb: 3, pl:0 }} size="small" onClick={() => { navigate(Paths.ProductServices) }} startIcon={<ChevronLeft />}>Back</Button>
            <Typography variant="h5" pb={2}>Pharmacy Benefit Management (PBM)</Typography>
            <Typography variant="body1" pb={2}>
                <Typography component="p" pb={2}>Pharmacy Benefit Management (PBM) helps <Typography color="primary" component="span" variant="subtitle1">manage prescription drug benefits</Typography> on behalf of health insurance plans, employers, and other organizations. PBM is responsible for securing lower drug costs for clients.</Typography>
                <Typography component="p" pb={2}>Experience a dedicated team of benefits professionals, delivering seamless and efficient third-party administration.</Typography>
                <Typography component="p" pb={2}>Our transformative services inspire highly motivated, health-conscious employees, enhancing overall efficiency.</Typography>
            </Typography>
            
        </Container>


        <MyFooter />
    </>;
}

export default ServicesPharmaBenefitMgmtPage;