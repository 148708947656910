import React, { PropsWithChildren } from "react"
import TopNavigation from "../components/topNav";
import TopHeaderBanner from "../components/topHeaderBanner";
import { globalStyles, joinSx } from "../utils/style";
import { Box, Button, Container, Divider, Grid, Typography } from "@mui/material";
import MyFooter from "../components/footer";
import { Banner_productList, ProductImg_logoSciGen, ProductImg_zometa } from "src/assets";
import { useNavigate } from "react-router-dom";
import Paths from "../utils/Paths";
import { ChevronLeft } from "@mui/icons-material";


export interface SciGenProps extends PropsWithChildren {

}
const SciGenPage: React.FC<SciGenProps> = (props) => {
    const navigate = useNavigate();

    return <>
        <TopNavigation />
        <TopHeaderBanner image={Banner_productList} />

        <Container sx={{ py: 4 }}>
            <Button sx={{color:"GrayText", mb:3}} size="small" onClick={() => {navigate(Paths.Products)}} startIcon={<ChevronLeft />}>Back</Button>
            <Typography variant="h5">Our Product</Typography>
            <Box width="300px" component="img" src={ProductImg_logoSciGen} pt={3} alt=""></Box>
            <Box pt={3}>
                <Box pb={2}>
                    <Grid container>
                        <Grid item xs={6} md={2}><img width="100%" src={ProductImg_zometa} alt="" /></Grid>
                        <Grid item xs={6} md={10} pl={3}>
                            <Typography variant="body2">
                                <p>Therapeutic segment: <br/>Oncology</p>
                                <p>
                                    Indication: 
                                    <ul>
                                        <li>Prevention of skeletal related events (pathological fractures, spinal compression, radiation or surgery to bone, or tumour-induced hypercalcemia) in patients with advanced malignancies involving bone.</li>
                                        <li>Treatment of hypercalcaemia of malignancy (HCM).</li>
                                    </ul>
                                </p>
                                <p>Strength: <br/>4mg/100mL solution for infusion</p>
                                <p>Dosage form: <br/>Ready-to-use vial</p>
                            </Typography>
                            <Button variant="contained" onClick={() => { window.open("https://www.mims.com/malaysia/drug/info/zometa?type=full", "_blank")}} color="warning" sx={joinSx(globalStyles.commonButton, { my: 3 })}>Read More</Button>
                        </Grid>
                    </Grid>
                    <Divider />
                </Box>
                
            </Box>
        </Container>


        <MyFooter />
    </>;
}

export default SciGenPage;