import { createSvgIcon } from '@mui/material/utils';
// import React from 'react';

const Logo = createSvgIcon(
    <svg x="-108" width="230" height="30" viewBox="0 0 230 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_166)">
            <path d="M128.654 10.4399H137.362C140.373 10.4399 143.112 11.2599 143.112 14.5099C143.112 17.7599 140.373 18.5999 137.362 18.5999H130.452V24.6599H128.654V10.4399ZM130.452 17.0699H136.892C139.882 17.0699 141.387 16.4599 141.387 14.5299C141.387 12.5999 139.871 11.9699 136.892 11.9699H130.452V17.0799V17.0699Z" fill="#009A8A" />
            <path d="M144.189 10.4399H145.987V16.4699H157.686V10.4399H159.484V24.6599H157.686V17.9899H145.987V24.6599H144.189V10.4399Z" fill="#009A8A" />
            <path d="M167.952 10.4399H170.158L177.79 24.6599H175.762L173.838 20.9599H164.251L162.307 24.6599H160.3L167.952 10.4399ZM173.044 19.4399L169.06 11.7899L165.077 19.4399H173.054H173.044Z" fill="#009A8A" />
            <path d="M178.543 10.4399H187.136C189.51 10.4399 192.981 10.9299 192.981 14.4099C192.981 17.3799 190.095 18.2399 188.297 18.3199L193.2 24.6599H190.973L186.185 18.3599H180.341V24.6599H178.543V10.4399ZM180.341 16.8399H186.718C189.311 16.8399 191.256 16.4899 191.256 14.3899C191.256 12.2899 189.311 11.9599 186.718 11.9599H180.341V16.8299V16.8399Z" fill="#009A8A" />
            <path d="M195.197 10.4399H197.978L203.477 22.4099L208.976 10.4399H211.757V24.6599H210.105V11.9699H210.063L204.25 24.6599H202.745L196.89 11.9699H196.849V24.6599H195.197V10.4399Z" fill="#009A8A" />
            <path d="M220.162 10.4399H222.368L230 24.6599H227.972L226.048 20.9599H216.461L214.517 24.6599H212.51L220.162 10.4399ZM225.254 19.4399L221.27 11.7899L217.287 19.4399H225.264H225.254Z" fill="#009A8A" />
            <path d="M56.7369 22.2799V18.5199H65.7069V16.1399H56.7369V12.8299H67.16V10.4399H38.0859V12.8299H44.735V24.6599H47.6414V12.8299H53.8305V24.6599H67.4109V22.2799H56.7369Z" fill="#1E398C" />
            <path d="M91.8536 10.4399H88.5395L82.3923 22.3799L79.6323 18.8699C81.8173 18.7499 83.7827 17.3099 83.7827 14.6799C83.7827 11.1999 81.2423 10.4399 77.7295 10.4399H68.7909V24.6599H71.6973V19.0699H76.2973L80.615 24.6599H84.3159L85.8945 21.3899H94.4673L96.025 24.6599H99.0882L91.8745 10.4399H91.8536ZM71.6973 16.7599V12.7499H77.3009C79.6741 12.7499 80.8763 13.0799 80.8763 14.7499C80.8763 16.4199 79.6741 16.7599 77.3009 16.7599H71.6973ZM87.0968 19.0099L90.2018 12.6699L93.3068 19.0099H87.0863H87.0968Z" fill="#1E398C" />
            <path d="M126.814 18.5599C126.814 23.1399 123.771 25.0099 118.921 25.0099C114.07 25.0099 111.027 23.1299 111.027 18.5599V10.4399H113.934V18.3599C113.934 20.7099 114.812 22.6199 118.921 22.6199C123.029 22.6199 123.907 20.6999 123.907 18.3599V10.4399H126.814V18.5599Z" fill="#1E398C" />
            <path d="M99.7782 21.9099C100.824 22.1599 101.827 22.3599 103.113 22.3599C106.532 22.3799 107.201 21.2099 107.159 19.1099V13.8999H110.065V19.2699C110.024 23.4599 107.839 24.8299 103.458 24.8299C102.068 24.8299 100.489 24.6899 99.7782 24.5199V21.9199V21.9099Z" fill="#1E398C" />
            <path d="M110.055 10.4399H107.065V13.0599H110.055V10.4399Z" fill="#FFCA05" />
            <path d="M22.4563 13.12V12.98C22.4563 12.31 21.8918 11.77 21.1913 11.77H17.8877C18.4313 12.54 18.8495 13.41 19.1109 14.34H21.1809C21.8813 14.34 22.4459 13.8 22.4459 13.13" fill="#FFCA05" />
            <path d="M21.275 7.99999H10.465C12.9636 7.99999 15.2322 8.97999 16.8527 10.57H21.275C22.7177 10.57 23.8781 11.69 23.8781 13.06C23.8781 14.43 22.7072 15.55 21.275 15.55H19.3618C19.4036 15.88 19.4245 16.23 19.4245 16.57V18.11H21.275C24.1918 18.11 26.565 15.85 26.565 13.05C26.565 10.25 24.2022 7.98999 21.275 7.98999" fill="#FFCA05" />
            <path d="M30.475 14.64C30.5064 6.58 23.6587 0 15.2323 0C10.7055 0 6.64912 1.89 3.85776 4.89C3.28276 5.51 3.5023 6.48 4.28639 6.81C4.79867 7.02 5.38412 6.89 5.75003 6.5C8.02912 4.05 11.3328 2.49 15.0128 2.43C21.965 2.32 27.7673 7.64 27.9241 14.29C28.0496 19.71 24.4637 24.35 19.4246 26.04V28.59C25.7809 26.85 30.4332 21.27 30.4646 14.64" fill="#009A8A" />
            <path d="M19.4346 26.04V16.57C19.4346 11.84 15.42 8 10.4646 8H2.44592C1.9441 8 1.4841 8.29 1.28547 8.73C0.459555 10.52 0.0100098 12.5 0.0100098 14.58C0.0100098 22.63 6.82638 29.15 15.2423 29.15C15.7964 29.15 16.3505 29.12 16.8837 29.07C17.7618 28.98 18.6087 28.82 19.4346 28.6V26.05V26.04ZM2.54001 14.57C2.54001 13.28 2.7491 12.04 3.13592 10.87C3.26138 10.48 3.86774 10.57 3.86774 10.98V15.28C3.86774 16.54 4.9341 17.57 6.26183 17.57H8.8232C9.15774 17.57 9.41911 17.83 9.41911 18.14V25.13C9.41911 25.24 9.30411 25.31 9.21002 25.26C5.23729 23.21 2.55047 19.19 2.55047 14.57M12.9841 26.52C12.6809 26.47 12.3882 26.4 12.085 26.33C12.0223 26.31 11.97 26.26 11.97 26.19V17.06C11.97 16 11.0605 15.13 9.94183 15.13H7.0041C6.66956 15.13 6.4082 14.87 6.4082 14.56C6.4082 14.25 6.68001 13.99 7.0041 13.99H10.2346C11.8446 13.99 13.1514 15.24 13.1514 16.78V26.38C13.1514 26.47 13.0677 26.54 12.9737 26.52M16.7477 26.63C16.4446 26.66 16.1414 26.69 15.8382 26.7C15.7546 26.7 15.6814 26.64 15.6814 26.56V16.71C15.6814 13.87 13.2768 11.57 10.3077 11.57H7.0041C6.66956 11.57 6.4082 11.31 6.4082 11C6.4082 10.69 6.68001 10.43 7.0041 10.43H10.6109C14.0714 10.43 16.8837 13.12 16.8837 16.43V26.49C16.8837 26.56 16.8314 26.62 16.7477 26.63Z" fill="#1E398C" />
        </g>
        <defs>
            <clipPath id="clip0_1_166">
                <rect width="230" height="29.15" fill="white" />
            </clipPath>
        </defs>
    </svg>
    ,
    'Logo'
);

export default Logo;


